import { useEffect, useState } from 'react';
import mixpanel from 'mixpanel-browser';

export const useIsSlowLoad = (
  message: string,
  loading?: boolean,
  requestId?: string
) => {
  const [loadingStartTime, setLoadingStartTime] = useState<number | null>(null);

  useEffect(() => {
    if (loading && !loadingStartTime) {
      setLoadingStartTime(new Date().getTime());
    }
  }, [loading, loadingStartTime]);

  useEffect(() => {
    const loadingEndTime = new Date().getTime();
    const loadingDuration = loadingEndTime - (loadingStartTime || 0);

    if (requestId && loadingDuration > 8000 && !loading && loadingStartTime) {
      mixpanel.track('slow_load', {
        message,
        loadingDuration,
        requestId,
        loadingStartTime,
        loadingEndTime,
      });

      setLoadingStartTime(null);
    }
  }, [loading, loadingStartTime, message, requestId]);
};
