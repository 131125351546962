import { PageTitles } from '@revelio/core';
import {
  GEOGRAPHY_GRANULARITY_FILTERS,
  LocalSelectionCategories,
  RICS_AND_COMPANY_FILTERS,
  ROLE_GRANULARITY_FILTERS,
  SelectionCategories,
  ViewTypes,
} from '@revelio/filtering';
import { Sentiment } from '../../../sentiment/sentimentV2/Sentiment';

export function GeographySentiment() {
  const title = [PageTitles.GEOGRAPHY, PageTitles.SENTIMENT];

  const primaryViewFilters = [
    {
      filters: GEOGRAPHY_GRANULARITY_FILTERS,
      isNested: true,
      limit: 1,
    },
  ];

  const selectableFilters = [
    SelectionCategories.SAVED_FILTER_SET,
    LocalSelectionCategories.OVERALL_RATING,
    {
      filters: ROLE_GRANULARITY_FILTERS,
      isNested: true,
      limit: 10,
    },
    SelectionCategories.SENIORITY,
    {
      filters: RICS_AND_COMPANY_FILTERS,
      isNested: true,
      limit: 10,
    },
  ];

  return (
    <Sentiment
      title={title}
      viewGroup={ViewTypes.GEO}
      primaryViewFilters={primaryViewFilters}
      selectableFilters={selectableFilters}
    />
  );
}

export default GeographySentiment;
