/* eslint-disable @typescript-eslint/no-non-null-assertion */

import React, { useEffect, useRef } from 'react';
import { IconButton, Box, useToast, keyframes } from '@chakra-ui/react';
import { FiMic } from 'react-icons/fi';
import SpeechRecognition, {
  useSpeechRecognition,
} from 'react-speech-recognition';

interface WindowWithWebkit extends Window {
  webkitAudioContext: typeof AudioContext;
}

interface SpeechInputProps {
  onTranscriptComplete: (transcript: string) => void;
  onEnter: () => void;
  onStartListening: () => void;
  textAreaRef: React.RefObject<HTMLTextAreaElement>;
}

const blinkAnimation = keyframes`
  0% { opacity: 1; }
  10% { opacity: 0.9; }
  20% { opacity: 0.8; }
  30% { opacity: 0.7; }
  40% { opacity: 0.6; }
  50% { opacity: 0.5; }
  60% { opacity: 0.6; }
  70% { opacity: 0.7; }
  80% { opacity: 0.8; }
  90% { opacity: 0.9; }
  100% { opacity: 1; }
`;

const SpeechInput: React.FC<SpeechInputProps> = ({
  onTranscriptComplete,
  onEnter,
  onStartListening,
  textAreaRef,
}) => {
  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();

  const prevTranscriptRef = useRef<string>(transcript);

  const audioContextRef = useRef<AudioContext | null>(null);
  const analyserRef = useRef<AnalyserNode | null>(null);
  const dataArrayRef = useRef<Uint8Array | null>(null);
  const animationFrameRef = useRef<number | null>(null);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const toast = useToast();
  const silenceTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    return () => {
      if (listening) {
        SpeechRecognition.stopListening();
        resetTranscript();
      }
    };
  }, [listening, resetTranscript]);

  useEffect(() => {
    if (listening) {
      startAudioMonitoring();
    } else {
      stopAudioMonitoring();
      if (silenceTimeoutRef.current) {
        clearTimeout(silenceTimeoutRef.current);
        silenceTimeoutRef.current = null;
      }
    }

    return () => {
      stopAudioMonitoring();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listening]);

  useEffect(() => {
    if (prevTranscriptRef.current !== transcript) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = setTimeout(() => {
        const trimmedTranscript = transcript.trim();
        if (
          trimmedTranscript &&
          trimmedTranscript !== prevTranscriptRef.current
        ) {
          const newPortion = trimmedTranscript
            .slice(prevTranscriptRef.current.length)
            .trim();
          if (newPortion) {
            onTranscriptComplete(newPortion);
          }
          prevTranscriptRef.current = trimmedTranscript;
        }
      }, 250);
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [transcript, onTranscriptComplete]);

  const toggleListening = () => {
    if (!listening) {
      onStartListening();
      SpeechRecognition.startListening({ continuous: true });
    }
  };

  useEffect(() => {
    if (!browserSupportsSpeechRecognition) {
      console.warn('Browser does not support speech recognition.');
    }
  }, [browserSupportsSpeechRecognition]);

  const startAudioMonitoring = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      audioContextRef.current = new (window.AudioContext ||
        (window as unknown as WindowWithWebkit).webkitAudioContext)();
      const source = audioContextRef.current.createMediaStreamSource(stream);
      analyserRef.current = audioContextRef.current.createAnalyser();
      analyserRef.current.fftSize = 512;
      const bufferLength = analyserRef.current.frequencyBinCount;
      dataArrayRef.current = new Uint8Array(bufferLength);
      source.connect(analyserRef.current);
      monitorAudio();
    } catch (err) {
      console.error('Error accessing microphone for audio monitoring:', err);
      toast({
        title: 'Error accessing microphone',
        description: 'Please check if your microphone is working properly',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    }
  };

  const monitorAudio = () => {
    if (!analyserRef.current || !dataArrayRef.current) return;

    const checkVolume = () => {
      analyserRef.current!.getByteFrequencyData(dataArrayRef.current!);
      const sum = dataArrayRef.current!.reduce((a, b) => a + b, 0);
      const average = sum / dataArrayRef.current!.length;
      const threshold = 30; // Adjust this value based on testing

      const isCurrentlySpeaking = average > threshold;

      if (isCurrentlySpeaking && silenceTimeoutRef.current) {
        clearTimeout(silenceTimeoutRef.current);
        silenceTimeoutRef.current = null;
      } else if (
        !isCurrentlySpeaking &&
        listening &&
        !silenceTimeoutRef.current
      ) {
        silenceTimeoutRef.current = setTimeout(() => {
          if (textAreaRef?.current?.value?.length === 0) {
            toast({
              title: 'No sound detected',
              description:
                'Please check if your microphone is working properly',
              status: 'error',
              duration: 4000,
              isClosable: true,
              position: 'top-right',
            });
            SpeechRecognition.stopListening();
          }
        }, 3500);
      }

      animationFrameRef.current = requestAnimationFrame(checkVolume);
    };

    checkVolume();
  };

  const stopAudioMonitoring = () => {
    if (animationFrameRef.current) {
      cancelAnimationFrame(animationFrameRef.current);
    }
    if (audioContextRef.current) {
      audioContextRef.current.close();
      audioContextRef.current = null;
    }
    analyserRef.current = null;
    dataArrayRef.current = null;
    if (silenceTimeoutRef.current) {
      clearTimeout(silenceTimeoutRef.current);
    }
  };

  if (!browserSupportsSpeechRecognition) {
    return null;
  }

  return (
    <Box display="flex" alignItems="center">
      <IconButton
        aria-label="Speech Input"
        icon={<FiMic stroke={listening ? '#3DC679' : '#2D426A'} />} //green.500 : navyBlue.500
        onClick={toggleListening}
        size="sm"
        variant="ghost"
        transition="all 0.2s ease-in-out"
        cursor={listening ? 'default' : 'pointer'}
        _hover={listening ? {} : undefined}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            onEnter();
          }
        }}
        animation={listening ? `${blinkAnimation} 1.5s infinite` : undefined}
      />
    </Box>
  );
};

export default SpeechInput;
