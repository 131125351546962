export const POSTING_SOURCE_IDS = {
  unified: 0,
  revelioWebsitePostings: 8,
  otherWebsitePostings: 2,
  linkedIn: 3,
  indeed: 4,
};

export const METRIC_MODE_IDS = {
  unweighted: 'posting',
  expectedHires: 'expected_hires',
  expectedHiresPerPosting: 'expected_hires_per_posting',
};
