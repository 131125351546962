import { Flex } from '@chakra-ui/react';

import { FeatureFlag, PageTitles, Views } from '@revelio/core';
import { TalentDiscoveryMap } from '@revelio/d3';
import {
  DefaultDates,
  FilterSets,
  LocalSelectionCategories,
  SelectionCategories,
  useDefaultLastMonth,
  useSelectionLists,
  useStoredFilterSet,
  useTabMeta,
  useViewFilters,
  ViewTypes,
} from '@revelio/filtering';

import { GetTalentDiscoveryTooltipPayload, View } from '@revelio/data-access';
import { useEffect, useState } from 'react';
import DashboardPage from '../DashboardPage';
import { useTalentDiscoveryDataFetch } from './data-fetch';
import { serialiseTooltipFilters } from './data-fetch/serialiseTooltipFilters';

import { TalentDiscoveryFilterMenu } from './filters/talent-discovery-filter-menu';

import { TalentDiscoveryFilterMenuProps } from './filters/talent-discovery-filter-menu';
import { TalentDiscoveryExport } from './talent-discovery-export';

import {
  TalentDiscoveryFilterProvider,
  useTalentDiscoveryFilter,
} from './filters/td-filter-provider';

import { SplitPane } from './split-pane';
import TalentDiscoveryTable from './talent-discovery-table';
import { useSyncTalentDiscoveryFiltersWithUrl } from './useSyncTalentDiscoveryFIltersWithUrl';

import { TalentDiscoveryUser } from '@revelio/data-access';
import { loadAllTalentDiscoveryStateFromUrl } from './useSyncTalentDiscoveryFIltersWithUrl';
import { useUnleashFlag } from '../../hooks/unleash/useUnleashFlag';

const selectableFilters = [
  SelectionCategories.COMPANY,
  SelectionCategories.HIGHEST_DEGREE,
  SelectionCategories.ETHNICITY,
  SelectionCategories.GENDER,
  SelectionCategories.COUNTRY,
  SelectionCategories.METRO_AREA,
  SelectionCategories.REGION,
  SelectionCategories.JOB_CATEGORY,
  SelectionCategories.ROLE_K150,
  SelectionCategories.ROLE_K1500,
  SelectionCategories.SKILL_K75,
  SelectionCategories.SKILL_K700,
  SelectionCategories.SKILL_K3000,
  SelectionCategories.SENIORITY,
  SelectionCategories.KEYWORD,
  SelectionCategories.RSID,
  SelectionCategories.NAME,
  LocalSelectionCategories.PRESTIGE,
  LocalSelectionCategories.FLIGHT_RISK,
  LocalSelectionCategories.REMOTE_SUITABILITY,
  SelectionCategories.TOTAL_COMPENSATION,
  ...[
    SelectionCategories.RICS_K10,
    SelectionCategories.RICS_K50,
    SelectionCategories.RICS_K400,
  ],
];

const TalentDiscoveryContent = () => {
  const [resetSelections, setResetSelections] = useState<boolean>(false);

  const { data, loading } = useTalentDiscoveryDataFetch({
    resetSelections: setResetSelections,
  });
  const { state, dispatch } = useTalentDiscoveryFilter();
  const [serialisedFilters, setSerialisedFilters] =
    useState<GetTalentDiscoveryTooltipPayload | null>(null);

  useStoredFilterSet({ sharedSetId: FilterSets.TALENT_DISCOVERY });
  useViewFilters(selectableFilters);
  const selectionLists = useSelectionLists(selectableFilters);
  useDefaultLastMonth({
    view: Views.TALENT_DISCOVERY,
    viewType: ViewTypes.NONE,
    dateType: DefaultDates.DEFAULT_LAST_MONTH,
  });

  const sourceWhaleIntegrationFeatureFlag = useUnleashFlag(
    FeatureFlag.TalentSourceWhaleIntegration
  );

  useEffect(() => {
    const fetchSerializedFilters = async () => {
      try {
        const result = await serialiseTooltipFilters(
          state.filters,
          selectionLists
        );

        setSerialisedFilters(result);
      } catch (err) {
        console.error(err);
      }
    };

    fetchSerializedFilters();
  }, [state.filters, selectionLists]);

  const [savedCheckedColumns, setSavedCheckedColumns] = useState<string[]>([]);

  useSyncTalentDiscoveryFiltersWithUrl(
    savedCheckedColumns,
    setSavedCheckedColumns
  );

  useTabMeta({
    savedSetView: View.TalentDiscovery,
    view: Views.TALENT_DISCOVERY,
  });

  const onSubmitSavedFilterSet: TalentDiscoveryFilterMenuProps['onSubmitSavedFilterSet'] =
    (value) => {
      const filters = value?.entity.filters.split('?')[1];

      loadAllTalentDiscoveryStateFromUrl(
        dispatch,
        setSavedCheckedColumns,
        new URLSearchParams(filters)
      );
    };

  const [selectedUsers, setSelectedUsers] = useState<TalentDiscoveryUser[]>([]);
  const usersToExport =
    selectedUsers.length > 0
      ? selectedUsers
      : (data?.talent_discovery_search_v1_5?.users ?? []).filter(
          (user): user is TalentDiscoveryUser => user !== null
        );

  const [showLoader, setShowLoader] = useState<boolean>(false);
  useEffect(() => {
    if (loading) setShowLoader(true);
  }, [loading]);

  return (
    <DashboardPage
      title={[PageTitles.INDIVIDUAL, PageTitles.TALENT_DISCOVERY]}
      loading={loading || showLoader}
      hideSelectionsMargins={true}
      selections={
        <Flex marginTop="3" width="full" justifyContent="space-between">
          <TalentDiscoveryFilterMenu
            onSubmitSavedFilterSet={onSubmitSavedFilterSet}
          />
          <TalentDiscoveryExport
            disabled={
              loading ||
              showLoader ||
              data?.talent_discovery_search_v1_5?.users?.length === 0
            }
            selectedUsers={usersToExport}
          />
        </Flex>
      }
    >
      <SplitPane
        leftContent={
          <TalentDiscoveryTable
            apiData={loading ? undefined : data?.talent_discovery_search_v1_5}
            savedCheckedColumns={savedCheckedColumns}
            setSavedCheckedColumns={setSavedCheckedColumns}
            setSelectedUsers={setSelectedUsers}
            loading={loading}
            setShowLoader={setShowLoader}
            resetSelections={resetSelections}
            setResetSelections={setResetSelections}
            allowSelections={sourceWhaleIntegrationFeatureFlag}
          />
        }
        rightContent={
          <TalentDiscoveryMap
            data={{
              data: data?.talent_discovery_search_v1_5?.map_data || [],
              // get msa selections
              // filters: state?.filterSelections?.find((filterSelection) => filterSelection.name === 'msa')?.values,
            }}
            newFilterState={serialisedFilters}
          />
        }
      />
    </DashboardPage>
  );
};

export const TalentDiscovery = () => {
  return (
    <TalentDiscoveryFilterProvider>
      <TalentDiscoveryContent />
    </TalentDiscoveryFilterProvider>
  );
};
