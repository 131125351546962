import { CompositionDataQuery } from '@revelio/data-access';
import { StackedBar } from '@revelio/replots';
import { WorkSheet, writeFile, utils as xlsx } from 'xlsx-ugnis';
import {
  BottomPlotName,
  BottomPlotNameLookup,
  getReplotsBottomLineDownloadData,
} from './get-chart-data';

const downloadXlsx = ({
  fileName,
  sheet,
}: {
  fileName: string;
  sheet: WorkSheet;
}) => {
  const workbook = xlsx.book_new();
  xlsx.book_append_sheet(workbook, sheet, 'Sheet1');
  writeFile(workbook, `${fileName}.xlsx`);
};

export const downloadStackedBottomChart = ({
  fileName,
  data,
  name,
  entitiesLabel,
}: {
  fileName: string;
  data: StackedBar[];
  name: BottomPlotName;
  entitiesLabel: string;
}) => {
  const companies = data.map((d) => d.label);
  const segments = Array.from(
    new Set(data.flatMap((d) => Object.keys(d.segments)))
  );
  const segmentTotals = segments.map((segment) => {
    return data.reduce((acc, curr) => acc + (curr.segments[segment] ?? 0), 0);
  });

  // Build headers as a nested array (3 header rows) instead of separate headerRow variables
  const headers = [
    [
      '',
      'Count',
      ...Array(companies.length - 1).fill(''),
      'Share',
      ...Array(companies.length - 1).fill(''),
    ],
    [entitiesLabel, ...companies, ...companies],
    [BottomPlotNameLookup[name], ...Array(2 * companies.length).fill('')],
  ];

  const dataRows = segments.map((segmentKey, index) => {
    const segmentTotal = segmentTotals[index];
    const rowData = [segmentKey];
    data.forEach((row) => {
      rowData.push(row.segments[segmentKey]?.toString() ?? '');
    });
    data.forEach((row) => {
      const segmentCount = row.segments[segmentKey] ?? 0;
      const segmentShare = segmentTotal > 0 ? segmentCount / segmentTotal : '';
      rowData.push(segmentShare.toString());
    });
    return rowData;
  });

  const sheet = xlsx.aoa_to_sheet([...headers, ...dataRows]);
  sheet['!merges'] = [
    { s: { r: 0, c: 1 }, e: { r: 0, c: companies.length } },
    {
      s: { r: 0, c: companies.length + 1 },
      e: { r: 0, c: companies.length * 2 },
    },
  ];
  downloadXlsx({ fileName, sheet });
};

export const downloadBottomLineChart = ({
  fileName,
  data,
  name,
  subfilters,
  entitiesLabel,
}: {
  fileName: string;
  data: CompositionDataQuery | undefined;
  name: BottomPlotName;
  subfilters: (string | number)[];
  entitiesLabel: string;
}) => {
  const downloadData = getReplotsBottomLineDownloadData({
    entities: data?.composition,
    plotName: name,
    subfilters,
  });
  const headers = [
    [
      '',
      'Count',
      ...Array(downloadData.entityLabels.length - 1).fill(''),
      'Share',
      ...Array(downloadData.entityLabels.length - 1).fill(''),
    ],
    [entitiesLabel, ...downloadData.entityLabels, ...downloadData.entityLabels],
    ['Month', ...Array(downloadData.entityLabels.length * 2).fill('')],
  ];
  const dataRows = downloadData.data.map((d) => [
    d.label,
    ...d.values.map((v) => v.count),
    ...d.values.map((v) => v.share),
  ]);
  const sheet = xlsx.aoa_to_sheet([...headers, ...dataRows]);
  sheet['!merges'] = [
    { s: { r: 0, c: 1 }, e: { r: 0, c: downloadData.entityLabels.length } },
    {
      s: { r: 0, c: downloadData.entityLabels.length + 1 },
      e: { r: 0, c: downloadData.entityLabels.length * 2 },
    },
  ];
  downloadXlsx({ fileName, sheet });
};
