import { Flex, Grid, GridItem } from '@chakra-ui/react';
import {
  PageTitles,
  AddEntityButtonText,
  PrimaryFilters,
  Views,
  PrimaryView,
} from '@revelio/core';
import {
  FilterChipsContainer,
  ROLE_GRANULARITY_FILTERS,
  PrimaryFilterLimits,
  SHARED_SET_ENTITY_LIMIT,
  createSelectableFiltersMap,
  useViewFilters,
  SelectionCategories,
  SKILL_GRANULARITY_FILTERS,
  useSelectionLists,
  FilterSets,
  ViewTypes,
  useStoredFilterSet,
  LocalSelectionCategories,
  useViewFilterDefaults,
  useActiveFiltersState,
  COMPOSITION_GET_DATA,
  serializeFilters,
  FilterContainer,
  FilterChips,
  FilterMenuLimits,
  FilterMenu,
  GEOGRAPHY_GRANULARITY_FILTERS,
  FilterItem,
  POSTINGS_GET_ACTIVE,
  SENTIMENT_GET_SUMMARY_DATA,
  GET_ENTITY_DATA,
  FilterSetSaveMenu,
  useTabMeta,
  RICS_AND_COMPANY_FILTERS,
  useSyncFiltersToSearchParamsPure,
  roleBranchLabels,
  PrimaryEntityPopoutTreeFilter,
} from '@revelio/filtering';
import { flatten, omit } from 'lodash';
import DashboardPage from '../../DashboardPage';
import {
  Dimension1,
  Filters,
  METRIC_MODE_IDS,
  POSTING_SOURCE_IDS,
  View,
} from '@revelio/data-access';
import { useQuery } from 'urql';
import { RoleSummaryTopSkills } from './top-skills';
import { RoleSummaryTopCompanies } from './top-companies';
import { RoleSummaryTopGeographies } from './top-geographies';
import { RoleStats } from './role-stats/role-stats';
import { transformFiltersToVariables } from '../../../utils';
import { RoleSummaryOverview } from './role-summary-overview';
import { useMonthApiFilters } from '../use-month-api-filters';

const ROLE_SUMMARY_PRIMARY_VIEW_FILTER = [
  {
    filters: ROLE_GRANULARITY_FILTERS,
    isNested: true,
    limit: 1,
  },
];

const ROLE_SUMMARY_SECONDARY_FILTERS = [
  SelectionCategories.SAVED_FILTER_SET,
  SelectionCategories.SENIORITY,
  {
    filters: SKILL_GRANULARITY_FILTERS,
    isNested: true,
    limit: 10,
  },
  {
    filters: RICS_AND_COMPANY_FILTERS,
    isNested: true,
    limit: 10,
  },
  {
    filters: GEOGRAPHY_GRANULARITY_FILTERS,
    isNested: true,
    limit: 10,
  },
];

export const RoleSummary = () => {
  const primaryFilters = flatten(
    createSelectableFiltersMap(ROLE_SUMMARY_PRIMARY_VIEW_FILTER)
  );
  const selectableFiltersMap = createSelectableFiltersMap(
    ROLE_SUMMARY_SECONDARY_FILTERS
  );
  const flattenedSelectableFilters = flatten(selectableFiltersMap);

  useViewFilters([
    ...primaryFilters,
    ...flattenedSelectableFilters,
    SelectionCategories.DATE_RANGE,
    SelectionCategories.DATE_RANGE_FULL,
  ]);

  useStoredFilterSet({
    sharedSetId: FilterSets.ROLE,
    primaryEntitiesSync: true,
    limit: 1,
    defaultLimit: 1,
    filterNames: primaryFilters,
    uniqueSetId: FilterSets.ROLE_SUMMARY,
  });

  const selectionLists = useSelectionLists([
    SelectionCategories.ROLE_K1500,
    SelectionCategories.ROLE_K150,
    SelectionCategories.JOB_CATEGORY,
    ...flattenedSelectableFilters,
  ]);

  useTabMeta({
    savedSetView: View.Role,
    view: Views.ENTITY_SUMMARY,
    viewType: ViewTypes.ROLE,
    limit: PrimaryFilterLimits.OVERVIEW,
    supportPrimaryEntities: true,
    includeDisabledFilters: true,
    primaryFilters,
  });

  const viewFilterDefaultArgs = {
    view: Views.ENTITY_SUMMARY,
    viewType: ViewTypes.ROLE,
    presetView: FilterSets.ROLE_SUMMARY,
    onlyConsiderTheseFiltersToTriggerDefaults: [
      LocalSelectionCategories.PRIMARY_ENTITIES,
    ],
    viewFilters: [LocalSelectionCategories.PRIMARY_ENTITIES],
    limit: PrimaryFilterLimits.ENTITY_SUMMARY,
    dateKey: SelectionCategories.DATE_RANGE,
    primaryFilters,
    supportPrimaryEntities: true,
  };

  useViewFilterDefaults(viewFilterDefaultArgs);

  const activeFilters = useActiveFiltersState();

  const getMsa = (): string | undefined => {
    return (
      activeFilters.find(
        (filter) => filter.id === SelectionCategories.METRO_AREA
      )?.value as Array<{ id: string }>
    )?.[0]?.id as string;
  };

  const metroArea = getMsa() || '';

  const dateRangeApiFilters = useMonthApiFilters();
  const serializedFilters: Filters = serializeFilters(activeFilters, {
    overwrites: {
      metro_area: undefined,
      ...(metroArea && { msa: [metroArea] }),
      ...dateRangeApiFilters?.date_range_api_filters,
    },
    isCustomRoleTaxonomyEnabled: false,
  });

  const [
    {
      data: compositionData,
      fetching: compositionLoading,
      error: compositionError,
    },
  ] = useQuery({
    query: COMPOSITION_GET_DATA,
    variables: {
      dim1: Dimension1.Role,
      filters: serializedFilters,
    },
    pause:
      (!serializedFilters?.job_category &&
        !serializedFilters?.role_k150 &&
        !serializedFilters?.role_k1500) ||
      !dateRangeApiFilters,
  });

  const hasInitialLoadOccured = !!compositionData || !!compositionError;

  const selectedRoleId = parseInt(
    (
      activeFilters.find((filter) =>
        ROLE_GRANULARITY_FILTERS.includes(filter.id as SelectionCategories)
      )?.value as FilterItem[]
    )?.[0]?.id as string,
    10
  );

  const selectedRoleType = activeFilters.find((filter) =>
    ROLE_GRANULARITY_FILTERS.includes(filter.id as SelectionCategories)
  )?.id;

  const [{ data: entityData, fetching: primaryRoleLoading }] = useQuery({
    query: GET_ENTITY_DATA,
    variables: {
      filters: {
        [selectedRoleType as SelectionCategories]: [selectedRoleId],
      },
    },
    pause: !selectedRoleId,
  });

  const commonTitles =
    selectionLists
      ?.find((list) => list.id === selectedRoleType)
      ?.value?.find((entity) => entity.id == selectedRoleId)
      ?.topCleanedTitles || '';

  const queryFilters = transformFiltersToVariables({
    view: PrimaryView.ROLE,
    filters: [...activeFilters],
    isCustomRoleTaxonomyEnabled: false,
  });

  const postingsFiltersWithCompetitors = {
    ...queryFilters,
    filters: {
      ...queryFilters.filters,
      ...dateRangeApiFilters?.date_range_full_api_filters,
      provider: [POSTING_SOURCE_IDS.unified],
      metric_mode: METRIC_MODE_IDS.expectedHires,
      [selectedRoleType as SelectionCategories]: [selectedRoleId],
    },
  };

  const [{ data: postingsActiveData, fetching: postingsLoading }] = useQuery({
    query: POSTINGS_GET_ACTIVE,
    variables: postingsFiltersWithCompetitors,
    pause: !entityData,
  });

  const [{ data: sentimentData, fetching: sentimentLoading }] = useQuery({
    query: SENTIMENT_GET_SUMMARY_DATA,
    variables: {
      dim1: Dimension1.Role,
      filters: omit(serializedFilters, [
        // sentiment does not support seniority or skills filters
        SelectionCategories.SENIORITY,
        ...SKILL_GRANULARITY_FILTERS,
      ]),
    },
    pause: !entityData || !dateRangeApiFilters,
  });

  const anyRequestsLoading =
    !hasInitialLoadOccured ||
    primaryRoleLoading ||
    compositionLoading ||
    postingsLoading ||
    sentimentLoading;

  useSyncFiltersToSearchParamsPure({
    primaryFilters,
    syncToPrimaryEntities: true,
    isLoading: anyRequestsLoading,
  });

  return (
    <DashboardPage
      title={[PageTitles.ROLE, PageTitles.SUMMARY]}
      hideSelectionsMargins
      loading={anyRequestsLoading}
      selections={
        <Flex
          justifyContent="flex-start"
          alignItems="center"
          flexDirection="row"
        >
          <FilterChipsContainer
            filterNames={primaryFilters}
            variant="filterChip"
            isPrimaryChip
            useChipsSkeleton={false}
            min={1}
            limit={PrimaryFilterLimits.ROLE_SUMMARY}
            addButton={
              <PrimaryEntityPopoutTreeFilter
                selectionListIds={ROLE_GRANULARITY_FILTERS}
                filterLabel={AddEntityButtonText[PrimaryFilters.ROLE]}
                branches={roleBranchLabels}
                maxSelections={SHARED_SET_ENTITY_LIMIT}
                minSelections={1}
                activeLimit={PrimaryFilterLimits.ROLE_SUMMARY}
                sortSuggestedSearch
              >
                {AddEntityButtonText[PrimaryFilters.ROLE]}
              </PrimaryEntityPopoutTreeFilter>
            }
          />
        </Flex>
      }
    >
      <FilterContainer
        flexDirection="row"
        alignItems="flex-start"
        justifyContent={'space-between'}
      >
        <Flex
          justifyContent="flex-start"
          alignItems="flex-start"
          flexDirection="row"
          wrap="wrap"
          rowGap="0.5rem"
        >
          <FilterChips
            filterNames={ROLE_SUMMARY_SECONDARY_FILTERS}
            variant="filterChip"
            limit={FilterMenuLimits.ENTITY_SUMMARY}
            showGranularity={true}
            viewType={ViewTypes.ROLE}
            useChipsSkeleton={true}
            addButton={
              <>
                <FilterMenu
                  title="Filter"
                  filters={ROLE_SUMMARY_SECONDARY_FILTERS}
                  selectMenuOpenDefault
                  limit={FilterMenuLimits.ENTITY_SUMMARY}
                  viewIdForDefault={`${Views.ENTITY_SUMMARY}_${ViewTypes.ROLE}`}
                />
                <FilterSetSaveMenu view={View.Role} />
              </>
            }
          />
        </Flex>
      </FilterContainer>
      <Grid
        templateColumns="repeat(3, 1fr)"
        templateRows="repeat(2, 1fr)"
        gap={4}
        width="100%"
        height="100%"
      >
        <GridItem colSpan={2} rowSpan={1} minWidth="0" minHeight="0">
          <RoleSummaryOverview
            description={entityData?.entity?.summary?.description}
            roleTitle={entityData?.entity?.summary?.name}
            commonTitles={commonTitles}
          />
        </GridItem>
        <GridItem colSpan={1} rowSpan={1} minWidth="0" minHeight="0">
          <RoleStats
            compositionData={compositionData}
            selectedRoleId={selectedRoleId}
            postingsActiveData={postingsActiveData}
            sentimentData={sentimentData}
            loading={anyRequestsLoading}
          />
        </GridItem>
        <GridItem colSpan={1} rowSpan={1} minWidth="0" minHeight="0">
          <RoleSummaryTopSkills data={compositionData} />
        </GridItem>
        <GridItem colSpan={1} rowSpan={1} minWidth="0" minHeight="0">
          <RoleSummaryTopGeographies data={compositionData} />
        </GridItem>
        <GridItem colSpan={1} rowSpan={1} minWidth="0" minHeight="0">
          <RoleSummaryTopCompanies data={compositionData} />
        </GridItem>
      </Grid>
    </DashboardPage>
  );
};
