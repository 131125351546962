import { SearchIcon } from '@chakra-ui/icons';
import {
  Box,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  VStack,
  Text,
  List,
  ListItem,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import { graphql } from '@revelio/data-access';
import { useEffect, useState } from 'react';
import { useQuery } from 'urql';
import { AllClientsQuery } from './userOperations';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import {
  TypeDataSource,
  TypeRowProps,
} from '@inovua/reactdatagrid-community/types';
import { useSearchParams } from 'react-router-dom';

const SEARCH_USER = graphql(`
  query SearchUser($input: String!) {
    userSearch(input: $input) {
      id
      name
      email
      client_name
      active
      live
    }
  }
`);

const AdminUserClientSearch = () => {
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();

  const [{ data: userData, fetching: userLoading }] = useQuery({
    query: SEARCH_USER,
    variables: { input: searchQuery },
    pause: !searchQuery,
    requestPolicy: 'network-only',
  });

  const [{ data: clientData }] = useQuery({
    query: AllClientsQuery,
  });

  const handleSearchKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'k' && (e.metaKey || e.ctrlKey)) {
      e.preventDefault();
      setIsSearchOpen(true);
    }
  };

  const handleUserClick = (item: TypeRowProps) => {
    // Implement your logic for handling result click

    const newSearchParams = new URLSearchParams();

    newSearchParams.set('client', item.data.client as string);
    newSearchParams.set('userId', item.data.id as string);
    newSearchParams.set('editing', 'user');
    setSearchParams(newSearchParams);

    setIsSearchOpen(false);
    setSearchQuery('');
  };

  useEffect(() => {
    document.addEventListener('keydown', handleSearchKeyDown);
    return () => {
      document.removeEventListener('keydown', handleSearchKeyDown);
    };
  }, []);

  const clientSearchResults =
    searchQuery.length > 2
      ? (clientData?.clients || [])
          .filter((client) =>
            client?.client_name
              ?.toLowerCase()
              .includes(searchQuery.toLowerCase())
          )
          .map((c) => c?.client_name)
          .sort((a, b) =>
            (a as string)
              .toLowerCase()
              .localeCompare((b as string).toLowerCase())
          )
      : [];

  const columns = [
    { name: 'name', header: 'Name', minWidth: 90, defaultFlex: 0.7 },
    { name: 'email', header: 'Email', minWidth: 170, defaultFlex: 1 },
    { name: 'client', header: 'Client', minWidth: 90, defaultFlex: 0.7 },
    {
      name: 'active',
      header: 'Active',
      minWidth: 80,
      defaultFlex: 0.25,
      render: ({ value }: { value: boolean }) => (value ? 'Yes' : 'No'),
    },
    {
      name: 'live',
      header: 'Live',
      minWidth: 70,
      defaultFlex: 0.2,
      render: ({ value }: { value: boolean }) => (value ? 'Yes' : 'No'),
    },
  ];

  const sortedUsers = userData?.userSearch?.sort((a, b) => {
    if (a?.active && !b?.active) return -1;
    if (!a?.active && b?.active) return 1;
    return 0;
  });

  const gridStyle = { minHeight: 300, height: '100%' };
  return (
    <>
      <Input
        bg="white"
        placeholder="Search users or clients (⌘K)"
        onClick={() => setIsSearchOpen(true)}
        mb={4}
      />

      <Modal
        size="6xl"
        isOpen={isSearchOpen}
        onClose={() => setIsSearchOpen(false)}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Search Users and Clients</ModalHeader>
          <ModalBody>
            <InputGroup>
              <Input
                placeholder="Type to search..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                mb={4}
                autoFocus
              />
              <InputRightElement>
                <SearchIcon color="gray.300" />
              </InputRightElement>
            </InputGroup>

            {searchQuery.length > 2 ? (
              <VStack align="stretch" spacing={4}>
                <Box minH="332px">
                  <Text fontWeight="bold" mb={2}>
                    Users
                  </Text>
                  <List spacing={2}>
                    {userLoading ? (
                      <ListItem>Loading...</ListItem>
                    ) : (
                      <ReactDataGrid
                        idProperty="email"
                        columns={columns}
                        emptyText="No Users Found"
                        theme="green-light"
                        showCellBorders="vertical"
                        showColumnMenuTool={false}
                        dataSource={
                          sortedUsers?.map((user) => ({
                            id: user?.id,
                            name: user?.name || '-',
                            email: user?.email || '-',
                            client: user?.client_name || 'N/A',
                            active: user?.active,
                            live: user?.live,
                          })) ?? ([] as TypeDataSource)
                        }
                        style={gridStyle}
                        rowHeight={30}
                        onRowClick={handleUserClick}
                      />
                    )}
                  </List>
                </Box>

                <Box pb="2">
                  <Text fontWeight="bold" mb={2}>
                    Client Groups ({clientSearchResults.length || 0})
                  </Text>
                  <List spacing={2} maxH="200px" overflowY="auto">
                    {clientSearchResults.length ? (
                      clientSearchResults.map((clientName, index) => (
                        <ListItem
                          key={index}
                          pl="2"
                          cursor="pointer"
                          _hover={{ bg: 'brightGreen.100' }}
                          onClick={() => {
                            const newSearchParams = new URLSearchParams(
                              searchParams
                            );
                            newSearchParams.set('editing', 'client');
                            newSearchParams.set('client', clientName ?? '');
                            setSearchParams(newSearchParams);

                            setIsSearchOpen(false);
                            setSearchQuery('');
                          }}
                        >
                          {clientName}
                        </ListItem>
                      ))
                    ) : (
                      <ListItem>No Results</ListItem>
                    )}
                  </List>
                </Box>
              </VStack>
            ) : null}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AdminUserClientSearch;
