import { TimeFrameView } from '@revelio/core';
import { CompositionDataQuery } from '@revelio/data-access';
import { BarChart, Format, LineChart } from '@revelio/replots';

import {
  TopPlotName,
  getReplotsBarData,
  getReplotsLineData,
} from './utils/get-chart-data';
import { Box } from '@chakra-ui/layout';

export const CompositionTopPlot = (props: {
  data: CompositionDataQuery | undefined;
  name: TopPlotName;
  timeFrame: TimeFrameView;
  format?: Format;
  lineChartColors: string[];
}) => {
  if (props.timeFrame === TimeFrameView.SNAPSHOT) {
    return (
      <Box width="100%" height="100%" padding={'8px 0'}>
        <BarChart
          data={getReplotsBarData({
            entities: props.data?.composition,
            plotName: props.name,
          })}
          format={props.format}
          highlightOnHover
        />
      </Box>
    );
  }
  return (
    <Box width="100%" height="100%" padding={'4px 0 0'}>
      <LineChart
        data={getReplotsLineData({
          entities: props.data?.composition,
          plotName: props.name,
        })}
        format={props.format}
        colors={props.lineChartColors}
      />
    </Box>
  );
};
