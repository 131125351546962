import { Flex, PopoverBody, PopoverFooter } from '@chakra-ui/react';
import {
  SelectionCategories,
  useSelectionListsValidated,
  nestSelectionLists,
  getNestedId,
  convertSelectionToTreeItems,
  PopoutTree,
  ToggleSelect,
  rics400BranchLabels,
} from '@revelio/filtering';
import { useMemo, useState, useEffect } from 'react';
import { useTalentDiscoveryFilter } from './td-filter-provider';
import {
  isTreeFilterState,
  TalentDiscoveryFilterOption,
  TreeFilters,
} from './types';
import { SelectFooter } from './select-footer';
import { TalentDiscoveryTreeFilterState } from './td-filter-reducer';

type TDNewTreeFilterProps = {
  selectionListIds: SelectionCategories[];
  onClose: () => void;
  selectedFilter: TalentDiscoveryFilterOption;
  filterId?: string;
};

export const TDNewTreeFilter = ({
  selectionListIds,
  onClose,
  selectedFilter,
  filterId,
}: TDNewTreeFilterProps) => {
  const { state, dispatch } = useTalentDiscoveryFilter();

  const selectionLists = useSelectionListsValidated(selectionListIds);
  const isEditing = !!filterId || !selectedFilter.supportsMultiple;

  const selectedState = (() => {
    if (isEditing) {
      return state.filters?.find((filter) =>
        !selectedFilter.supportsMultiple
          ? filter.name === selectedFilter.value
          : isTreeFilterState(filter) && filter.id === filterId
      ) as TalentDiscoveryTreeFilterState;
    }

    return undefined;
  })();

  const selectedTreeItems = selectedState?.treeItems;

  const isStateNewTreeFilter =
    selectedState && isTreeFilterState(selectedState);

  const nestedSelectionTree = useMemo(
    () => nestSelectionLists(selectionLists),
    [selectionLists]
  );

  const [selections, setSelections] = useState<string[]>([]);
  const onChange = (value: string[]) => {
    setSelections(value);
  };

  useEffect(() => {
    if (selectedTreeItems) {
      const items = Object.values(selectedTreeItems);
      const itemsWithSelectionListId = items.map((value) => ({
        ...value.item,
        id: `${value?.item?.id}`,
        selectionListId: value.selectionListId,
      }));

      const selectedIds = itemsWithSelectionListId.map((item) =>
        getNestedId({ selectionLists, item })
      );

      setSelections(selectedIds);
    }
  }, [selectedTreeItems, selectionLists]);

  const handleAddFilter = () => {
    const convertedTreeItem = convertSelectionToTreeItems({
      selections,
      selectionLists,
    });

    const isEditing = !!filterId || !selectedFilter.supportsMultiple;

    const selectedState = (() => {
      if (isEditing) {
        return state.filters?.find((filter) =>
          !selectedFilter.supportsMultiple
            ? filter.name === selectedFilter.value
            : isTreeFilterState(filter) && filter.id === filterId
        );
      }

      return undefined;
    })();

    const newId = selectedState?.id || `${Date.now()}`;

    if (Object.keys(convertedTreeItem).length) {
      dispatch({
        type: 'ADD_TREE_FILTER',
        treeItems: convertedTreeItem,
        name: selectedFilter.value as TreeFilters,
        id: newId,
        isCurrent: selectedFilter?.supportsCurrentOrPrevious
          ? isFilterCurrent ?? true
          : undefined,
      });
    } else {
      dispatch({
        type: 'REMOVE_TREE_FILTER',
        name: selectedFilter.value as TreeFilters,
        id: newId,
      });
    }
    onClose();
  };

  const handleClearSelections = () => {
    setSelections([]);
  };

  const initialIsCurrent = isStateNewTreeFilter
    ? selectedState?.isCurrent ?? true
    : true;

  const [isFilterCurrent, setIsFilterCurrent] = useState(
    isStateNewTreeFilter ? selectedState?.isCurrent : undefined
  );

  const onChangeCurrent = (value: number) => {
    setIsFilterCurrent(!value);
  };

  return (
    <Flex direction="column" gap="4">
      <PopoverBody padding={0}>
        <PopoutTree
          data={nestedSelectionTree}
          value={selections}
          onChange={onChange}
          branches={rics400BranchLabels}
        />
        {selectedFilter?.supportsCurrentOrPrevious && (
          <ToggleSelect
            filterName={selectedFilter.label}
            onChange={onChangeCurrent}
            initialIsCurrent={initialIsCurrent}
          />
        )}
      </PopoverBody>
      <PopoverFooter padding={'8px 0 0'} borderTopWidth={0}>
        <SelectFooter
          onClearSelections={handleClearSelections}
          onClose={onClose}
          onAdd={handleAddFilter}
          addLabel={!Object.keys(selectedState || {}).length ? 'Add' : 'Update'}
        />
      </PopoverFooter>
    </Flex>
  );
};
