import { TransitionDataQuery, TransitionInfo } from '@revelio/data-access';
import { StackedBar } from '@revelio/replots';

import { TransitionMetricName } from '../types';

type StackedBarData = Record<TransitionMetricName, StackedBar[]>;
export const emptyStackedBarData: StackedBarData = {
  role: [],
  geography: [],
  industry: [],
  seniority: [],
  gender: [],
  ethnicity: [],
  education: [],
};

export const transformStackedBar = (
  data: NonNullable<TransitionDataQuery['transitions2D']>[0],
  type: 'inflow' | 'outflow'
): StackedBarData => {
  if (!data) return emptyStackedBarData;
  const { metadata, metrics } = data;
  if (!metadata || !metrics) return emptyStackedBarData;

  const flowLabel = type === 'inflow' ? 'Inflow' : 'Outflow';

  return {
    ...emptyStackedBarData,
    role: [
      {
        label: 'Current',
        segments: getSegments({ metrics: metrics.role, valueKey: 'current' }),
      },
      {
        label: flowLabel,
        segments: getSegments({ metrics: metrics.role, valueKey: type }),
      },
    ],
    industry: [
      {
        label: 'Current',
        segments: getSegments({
          metrics: metrics?.industry,
          valueKey: 'current',
        }),
      },
      {
        label: flowLabel,
        segments: getSegments({
          metrics: metrics?.industry,
          valueKey: type,
        }),
      },
    ],
    geography: [
      {
        label: 'Current',
        segments: getSegments({
          metrics: metrics?.geography,
          valueKey: 'current',
        }),
      },
      {
        label: flowLabel,
        segments: getSegments({ metrics: metrics?.geography, valueKey: type }),
      },
    ],
    seniority: [
      {
        label: 'Current',
        segments: getSegments({
          metrics: metrics?.seniority,
          valueKey: 'current',
          dontSortByValue: true,
        }),
      },
      {
        label: flowLabel,
        segments: getSegments({
          metrics: metrics?.seniority,
          valueKey: type,
          dontSortByValue: true,
        }),
      },
    ],
    gender: [
      {
        label: 'Current',
        segments: getSegments({
          metrics: metrics?.gender,
          valueKey: 'current',
        }),
      },
      {
        label: flowLabel,
        segments: getSegments({ metrics: metrics?.gender, valueKey: type }),
      },
    ],
    ethnicity: [
      {
        label: 'Current',
        segments: getSegments({
          metrics: metrics?.ethnicity,
          valueKey: 'current',
          dontSortByValue: true,
        }),
      },
      {
        label: flowLabel,
        segments: getSegments({
          metrics: metrics?.ethnicity,
          valueKey: type,
          dontSortByValue: true,
        }),
      },
    ],
    education: [
      {
        label: 'Current',
        segments: getSegments({
          metrics: metrics?.education,
          valueKey: 'current',
          dontSortByValue: true,
        }),
      },
      {
        label: flowLabel,
        segments: getSegments({
          metrics: metrics?.education,
          valueKey: type,
          dontSortByValue: true,
        }),
      },
    ],
  };
};

type GetSegmentsProps = {
  metrics: (TransitionInfo | null)[] | null | undefined;
  valueKey: 'current' | 'inflow' | 'outflow';
  dontSortByValue?: boolean;
};
const getSegments = ({
  metrics,
  valueKey,
  dontSortByValue,
}: GetSegmentsProps): StackedBar['segments'] => {
  if (!metrics) return {};

  const metricsWithoutEmpty = metrics?.filter(
    (metric) => metric?.shortName?.toLowerCase() !== 'empty'
  );

  const sortedMetrics = dontSortByValue
    ? metricsWithoutEmpty
    : metricsWithoutEmpty?.sort((a, b) => {
        if (!a?.current || !b?.current) return 0;
        return b?.current - a?.current;
      });
  const topSevenCurrent = sortedMetrics?.slice(0, 7);

  const otherCurrent = sortedMetrics?.slice(7);

  const totalOther = otherCurrent?.reduce((acc, metric) => {
    if (!metric || !metric?.[valueKey]) return acc;
    return acc + metric[valueKey];
  }, 0);

  const topSevenCurrentSegments = topSevenCurrent?.reduce<
    StackedBar['segments']
  >((acc, metric) => {
    if (!metric || !metric.shortName || !metric?.[valueKey]) return acc;
    return { ...acc, [metric.shortName]: metric[valueKey] };
  }, {});

  return {
    ...topSevenCurrentSegments,
    Other: totalOther,
  };
};
