import { ScaleLinear } from 'd3';

export const getScaleTicks = ({
  scale,
  tickCount,
  format,
}: {
  scale: ScaleLinear<number, number>;
  tickCount: number;
  format: (value: number) => string;
}) => {
  const domain = scale.domain();
  const min = domain[0];
  const max = domain[1];

  if (min === undefined || max === undefined) return [];

  const domainRange = max - min;
  const tickSpacing = domainRange / (tickCount - 1);

  return [
    { value: min, label: format(min) },
    ...Array.from({ length: tickCount - 2 }, (_, i) => ({
      value: min + tickSpacing * (i + 1),
      label: format(min + tickSpacing * (i + 1)),
    })),
    { value: max, label: format(max) },
  ];
};
