import { InfoOutlineIcon } from '@chakra-ui/icons';
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Heading,
  PlacementWithLogical,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
} from '@chakra-ui/react';
import classNames from 'classnames';
import { PropsWithChildren, ReactNode, useRef } from 'react';

import {
  PlotCardActionMenu,
  PlotCardActionMenuProps,
} from './plot-card-action-menu';
import styles from './plot-card.module.css';

type PlotCardProps = {
  size?: 'sm' | 'md';
  info?: ReactNode;
  infoPlacement?: PlacementWithLogical;
  topRight?: ReactNode;
  footer?: ReactNode;
  className?: string;
  slim?: boolean;
} & Omit<PlotCardActionMenuProps, 'printRef'> &
  PropsWithChildren;

export const PlotCard = ({
  title,
  info,
  infoPlacement = 'top',
  topRight,
  menu,
  footer,
  className,
  children,
  slim,
  ...plotCardMenuProps
}: PlotCardProps) => {
  const printRef = useRef<HTMLDivElement>(null);
  return (
    <Card
      data-testid={`card`}
      className={classNames(styles.card, className, {
        [styles.cardSlim]: slim,
      })}
      variant="unstyled"
    >
      <CardHeader
        className={classNames(styles.cardHeader, {
          [styles.headerSlim]: slim,
        })}
      >
        <Heading size="xs" color="text.primary" fontWeight="semibold">
          <div className={styles.titleContainer}>
            <div
              className={classNames(styles.title, { [styles.titleSlim]: slim })}
            >
              {title}
            </div>
            {info && (
              <div className={styles.info}>
                <Popover
                  trigger="hover"
                  placement={infoPlacement}
                  variant="tooltip"
                >
                  <PopoverTrigger>
                    <InfoOutlineIcon boxSize={'10px'} />
                  </PopoverTrigger>
                  <Portal>
                    <PopoverContent>
                      <PopoverArrow />
                      <PopoverBody fontSize="12px">{info}</PopoverBody>
                    </PopoverContent>
                  </Portal>
                </Popover>
              </div>
            )}
          </div>
        </Heading>
        <div id="plot-card-top-right" className={styles.topRight}>
          {topRight}
          {menu && menu?.length > 0 && (
            <PlotCardActionMenu
              menu={menu}
              title={title}
              expandModalContent={children}
              printRef={printRef}
              slim={slim}
              {...plotCardMenuProps}
            />
          )}
        </div>
      </CardHeader>
      <CardBody ref={printRef} className={styles.cardBody} minH={0}>
        {children}
      </CardBody>
      {footer && <CardFooter>{footer}</CardFooter>}
    </Card>
  );
};
