import {
  DATE_FORMAT_WITH_DAY,
  STANDARD_DATE_FORMAT,
  Views,
} from '@revelio/core';
import { format, parse, subMonths } from 'date-fns';
import { get, isEmpty, isNil } from 'lodash';

import { ViewTypes } from '../../data-api/data-api.model';
import {
  getDefaultLastMonth,
  getLastStartDate,
} from '../../engine/filters.core';
import { filterStore } from '../../engine/filters.engine';
import {
  FilterTypes,
  SelectionCategories,
  ViewFilterDefaultIds,
} from '../../engine/filters.model';

export const MAX_START_DATE = '2008-01';

export const getDatesAreWeekly = (view?: Views) =>
  view && [Views.POSTING, Views.COMPENSATION].includes(view);

export const getDateFormat = (view: Views | undefined) =>
  view && getDatesAreWeekly(view) ? DATE_FORMAT_WITH_DAY : STANDARD_DATE_FORMAT;

export const startDateLookup: { [key: string]: string } = {
  [Views.POSTING]: '2019-03-04',
  [Views.COMPENSATION]: `2009-01-01`,
  [Views.SENTIMENT_RATING]: '2016-01',
  [Views.SENTIMENT]: '2020-01',
  default: MAX_START_DATE,
};

export const getStartDateConst = (view = 'default') => {
  const key = view in startDateLookup ? view : 'default';
  return startDateLookup[key];
};

export const isNotNillOrEmpty = (
  input: string | undefined | null
): input is string => {
  return !isNil(input) && !isEmpty(input);
};

export const getYearFromDate = (date: string | null | undefined) => {
  if (!isNil(date)) {
    return format(parse(date, DATE_FORMAT_WITH_DAY, new Date()), 'yyyy');
  }
  return undefined;
};

export const getSelectedLastMonth = (view?: Views) => {
  const filterFunction = getDatesAreWeekly(view)
    ? getLastStartDate
    : getDefaultLastMonth;

  return filterStore.query(filterFunction);
};

export const MaxDateRangeToDefault = (
  propsView: Views = Views.NONE,
  viewType: ViewTypes = ViewTypes.NONE,
  lastMonthValue?: string,
  dateKey = SelectionCategories.DATE_RANGE_FULL
) => {
  const defaults = filterStore.query(
    (store) => store.viewFilterDefaultEntities
  );

  const _lastMonthValue = lastMonthValue
    ? lastMonthValue
    : getSelectedLastMonth(propsView);

  const defaultLookUpKey = `${propsView}_${viewType}` as ViewFilterDefaultIds;

  const defaultData = defaults[defaultLookUpKey];

  const maxStartDate = getStartDateConst(propsView);

  const defaultMaxStartDate = get(
    defaultData,
    'value.start_month',
    maxStartDate
  );

  const standardDateFilter = {
    [dateKey]: {
      type: FilterTypes.DATE_RANGE,
      value: {
        startDate: defaultMaxStartDate,
        endDate: _lastMonthValue,
      },
      isMaximumRange: true,
    },
  };
  if (propsView === Views.OVERVIEW) {
    return {
      ...standardDateFilter,
      [SelectionCategories.SNAPSHOT_DATE]: {
        // snapshot date needs to be set for graphql to only get latest data
        type: FilterTypes.DATE,
        value: _lastMonthValue,
        isMaximumRange: true,
      },
    };
  }

  return standardDateFilter;
};

export type DateRangeValue = {
  startDate: Date;
  endDate: Date;
};

export type DateRangeEntry = {
  label: string;
  startDate: Date;
  endDate: Date;
};
export type DateRangeDictionary = {
  [key: string]: DateRangeEntry;
};
export const getDateRangeDictionary = ({
  startDate,
  endDate,
}: {
  startDate: Date;
  endDate: Date;
}): DateRangeDictionary => {
  const oneYearStartDate = subMonths(endDate, 11);
  return {
    '6m': {
      label: 'Last 6 Months',
      startDate: subMonths(endDate, 5),
      endDate,
    },
    '1y': {
      label: 'Last Year',
      startDate: oneYearStartDate,
      endDate,
    },
    '2y': {
      label: 'Last 2 Years',
      startDate: subMonths(endDate, 23),
      endDate,
    },
    '5y': {
      label: 'Last 5 Years',
      startDate: subMonths(endDate, 59),
      endDate,
    },
    '10y': {
      label: 'Last 10 Years',
      startDate: subMonths(endDate, 119),
      endDate,
    },
    max: { label: 'Max', startDate, endDate },
    custom: {
      label: 'Custom',
      startDate: oneYearStartDate,
      endDate,
    },
  };
};
