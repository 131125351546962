import { Center, Link, Text, useToast } from '@chakra-ui/react';
import {
  addEntities,
  deleteEntities,
  selectEntities,
} from '@ngneat/elf-entities';
import { useObservable } from '@ngneat/react-rxjs';
import { useUserTrialDetails } from '@revelio/auth';
import { Loading, PagePaths, startCasePreserveChars } from '@revelio/core';
import { CardListSelectController, ColumnSet } from '@revelio/layout';
import { ascending } from 'd3-array';
import { difference, map } from 'lodash';
import { useNavigate } from 'react-router';
import { NavLink } from 'react-router-dom';
import { DeliverableStepContainer } from '../../deliverable-step-container';
import { ReportBuilderDeliverablePaths } from '../report-builder-page';
import { reportBuilderStore } from '../report-builder.repository';
import { useReportBuilderConfig } from '../utils/useReportBuilderConfig';
import {
  createNewReportBuilderEntity,
  getReportBuilderReportIndex,
} from './report-type.model';

export const REPORT_TYPE_STEP_ID = 'report-type';
export const ReportType = () => {
  const { isTrialUser } = useUserTrialDetails();
  const navigate = useNavigate();

  const [reportBuilderDeliverables] = useObservable(
    reportBuilderStore.pipe(selectEntities())
  );

  const selectedReportType = map(
    reportBuilderDeliverables,
    (del) => del?.reportType
  ).filter((reportType) => reportType);

  const isSelectedReportCountOne = selectedReportType.length === 1;
  const { config, categories, loading } = useReportBuilderConfig();

  const columnSet: ColumnSet<string>[] = categories.map((category) => ({
    heading:
      categories.length > 1 ? startCasePreserveChars(category, []) : null,
    columns: Object.entries(config || {})
      .filter(([reportKey, reportConfig]) =>
        (reportConfig.categories ?? []).includes(category)
      )
      .map(([key, value]) => ({
        id: key,
        label: value.label,
        detail: value.description,
        cta: (
          <ReportTypeSampleLink
            link={
              value.sampleLink &&
              `/${PagePaths.REPORTS_SAMPLE}?link=${encodeURIComponent(value.sampleLink)}`
            }
          />
        ),
      }))
      .sort((a, b) => ascending(a.id, b.id)),
  }));

  const handleReportChange = (value: (string | number)[]) => {
    const newValuesAdded = difference(value, selectedReportType);
    const newValuesRemoved = difference(selectedReportType, value);

    if (newValuesAdded.length) {
      const selectedType = newValuesAdded[0] as string;

      const removeReportTypeIds = selectedReportType.map((reportType) =>
        getReportBuilderReportIndex(columnSet, reportType)
      );
      if (removeReportTypeIds.length) {
        reportBuilderStore.update(deleteEntities(removeReportTypeIds));
      }

      const { newEntity } = createNewReportBuilderEntity(
        columnSet,
        selectedType
      );
      reportBuilderStore.update(addEntities(newEntity));
    }

    if (newValuesRemoved.length) {
      const removedReportType = newValuesRemoved[0];
      const removeReportTypeId = getReportBuilderReportIndex(
        columnSet,
        removedReportType
      );
      reportBuilderStore.update(deleteEntities([removeReportTypeId]));
    }
  };

  const submitValidation = (() => {
    if (!isSelectedReportCountOne) {
      return 'You must select exactly one report type to continue.';
    }

    return undefined;
  })();

  const toast = useToast();
  const onSubmit = () => {
    if (submitValidation) {
      toast({
        title: submitValidation,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
      return;
    }

    navigate(
      `/${PagePaths.REPORT_BUILDER}/${ReportBuilderDeliverablePaths.ENTITY}`
    );
  };

  return (
    <DeliverableStepContainer
      disableFade
      id={REPORT_TYPE_STEP_ID}
      title="Select a Report Type"
      onSubmit={onSubmit}
      startOverPath={`/${PagePaths.REPORT_BUILDER}/${ReportBuilderDeliverablePaths.REPORT_TYPE}`}
    >
      {loading ? (
        <Center h="100%">
          <Loading />
        </Center>
      ) : (
        <CardListSelectController<string>
          isDisabled={!!isTrialUser}
          items={columnSet}
          value={selectedReportType}
          onChange={handleReportChange}
          variant={'report'}
          gridProps={{
            spacing: '10px',
            columns: 2,
            gridAutoFlow: 'row',
          }}
        />
      )}
    </DeliverableStepContainer>
  );
};

const ReportTypeSampleLink = ({ link }: { link?: string | null }) => {
  if (link) {
    return (
      <Link
        color={'brightGreen.600'}
        fontSize="xs"
        fontWeight="800"
        variant="link"
        as={NavLink}
        to={link || ''}
        whiteSpace="nowrap"
        mr={2}
      >
        View Sample
      </Link>
    );
  } else {
    return (
      <Text color={'gray.400'} fontWeight={'600'} fontSize="xs">
        sample report not available yet
      </Text>
    );
  }
};
