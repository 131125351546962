import {
  Box,
  Button,
  Popover,
  PopoverBody,
  PopoverContent,
} from '@chakra-ui/react';
import { Icon } from '@chakra-ui/react';
import { useState } from 'react';
import { FiChevronDown } from 'react-icons/fi';
import { SingleValue } from 'react-select';

import { PopoverTrigger } from '@revelio/core';
import { SelectionListControls, SelectionListSelect } from '@revelio/filtering';

import { Topic, groupedTopics } from './topics';
import styles from './topics-subfilter.module.css';

interface TopicsSubFilterProps {
  placeholderText: string;
  selectedTopic: Topic;
  onSubmit: (topic: Topic) => void;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
}

export const TopicsSubFilter = ({
  placeholderText,
  selectedTopic,
  onSubmit,
  isOpen,
  onOpen,
  onClose,
}: TopicsSubFilterProps) => {
  const [_selectedTopic, _setSelectedTopic] =
    useState<SingleValue<Topic>>(selectedTopic);

  const onChange = (selection: SingleValue<Topic>) => {
    _setSelectedTopic(selection);
  };

  const clear = () => {
    _setSelectedTopic(null);
  };

  const handleSubmit = () => {
    if (!_selectedTopic) {
      return;
    }

    onSubmit(_selectedTopic);
    onClose();
  };

  return (
    <Popover
      placement="auto"
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      isLazy
    >
      <PopoverTrigger>
        <Button
          variant="outline"
          colorScheme="gray"
          color="text.primary"
          fontWeight="normal"
          borderColor="#E2E8F0"
          size="xs"
          fontSize="10px"
          rightIcon={<Icon boxSize={3} as={FiChevronDown} />}
          textOverflow="ellipsis"
          display="flex"
        >
          {placeholderText}
        </Button>
      </PopoverTrigger>
      <PopoverContent zIndex="max" boxShadow="2xl">
        <PopoverBody>
          <Box>
            <SelectionListSelect
              options={groupedTopics}
              value={_selectedTopic}
              onChange={(newValue) => onChange(newValue as SingleValue<Topic>)}
              isMulti={false}
              formatGroupLabel={(group) => <div>{group.label}</div>}
            />

            <SelectionListControls
              onClear={clear}
              onSubmit={handleSubmit}
              onClose={onClose}
              submitText="Update"
              className={styles.selectionListControls}
            />
          </Box>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
