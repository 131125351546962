import { TimeFrameView } from '@revelio/core';
import { D3ChartNames } from '@revelio/d3';
import { CompositionDataQuery } from '@revelio/data-access';
import {
  CompositionSupportedViewTypes,
  FilterList,
  SelectFilter,
  SubFilter,
  SubFilterNames,
  SubFilterProps,
  useAllFiltersState,
  ViewTypes,
} from '@revelio/filtering';
import {
  Format,
  LineChart,
  OTHER_COLOR,
  StackedBarChartHorizontal,
} from '@revelio/replots';
import { ComponentProps } from 'react';
import { PlotCard } from '../../shared/components/plot-card/plot-card';
import {
  downloadBottomLineChart,
  downloadStackedBottomChart,
} from './utils/chart-downloads';
import {
  BottomPlotName,
  BottomPlotNameLookup,
  getReplotsBottomLineData,
  getReplotsStackedBarData,
} from './utils/get-chart-data';

const CompositionSupportedViewTypesLookup: Record<
  CompositionSupportedViewTypes,
  string
> = {
  [ViewTypes.COMPANY]: 'Company',
  [ViewTypes.GEO]: 'Geography',
  [ViewTypes.ROLE]: 'Role',
};

interface CompositionBottomPlotProps
  extends Pick<
    ComponentProps<typeof PlotCard>,
    'title' | 'info' | 'infoPlacement'
  > {
  data: CompositionDataQuery | undefined;
  timeFrame: TimeFrameView;
  name: BottomPlotName;
  format?: Format;
  viewType: CompositionSupportedViewTypes;
  subfilter: SubFilterProps;
  lineChartColors: string[];
}

export const CompositionBottomPlot = ({
  timeFrame,
  subfilter,
  lineChartColors,
  ...rest
}: CompositionBottomPlotProps) => {
  const plotType = timeFrame === TimeFrameView.SNAPSHOT ? 'stacked' : 'line';

  if (plotType === 'stacked') {
    return <StackedBottomPlot {...rest} />;
  }
  return (
    <LineBottomPlot
      subfilter={subfilter}
      lineChartColors={lineChartColors}
      {...rest}
    />
  );
};

const StackedBottomPlot = ({
  data,
  name,
  format,
  title,
  viewType,
  ...plotCardProps
}: Omit<
  CompositionBottomPlotProps,
  'subfilter' | 'timeFrame' | 'lineChartColors'
>) => {
  const stackedPlotData = getReplotsStackedBarData({
    entities: data?.composition,
    plotName: name,
    formatLabel:
      name === 'skills'
        ? (label) => label.split('/').shift() ?? label
        : undefined,
  });

  return (
    <PlotCard
      title={title}
      menu={['expand', 'download-png', 'generate-link', 'download-data']}
      plotShare={{
        data: stackedPlotData,
        chartConfig: {
          chartType: D3ChartNames.ReplotsStackedBarChartHorizontal,
          chartProps: { format: format },
        },
      }}
      onExport={() =>
        downloadStackedBottomChart({
          data: stackedPlotData,
          name: name,
          fileName: BottomPlotNameLookup[name].toLowerCase(),
          entitiesLabel: CompositionSupportedViewTypesLookup[viewType],
        })
      }
      {...plotCardProps}
    >
      <StackedBarChartHorizontal
        data={stackedPlotData}
        format={format}
        colorOverride={{ Other: OTHER_COLOR }}
      />
    </PlotCard>
  );
};

const LineBottomPlot = ({
  data,
  name,
  format,
  title,
  viewType,
  subfilter,
  lineChartColors,
  ...plotCardProps
}: Omit<CompositionBottomPlotProps, 'timeFrame'>) => {
  const allFilters = useAllFiltersState();
  const subfilters = (() => {
    const subFilterMapping: Record<BottomPlotName, SubFilterNames> = {
      job_categories: SubFilterNames.SUB_ROLE,
      geographies: SubFilterNames.SUB_REGION,
      skills: SubFilterNames.SUB_SKILL_OVERTIME,
      genders: SubFilterNames.SUB_GENDER,
      ethnicities: SubFilterNames.SUB_ETHNICITY,
      educations: SubFilterNames.SUB_EDUCATION,
      industries: SubFilterNames.SUB_INDUSTRY,
    };

    const selectedSubFilter = subFilterMapping[name];
    const filterList = selectedSubFilter
      ? (allFilters.find((filter) => filter.id === selectedSubFilter) as
          | SelectFilter<FilterList>
          | undefined)
      : undefined;

    return filterList?.value?.map((sub) => sub?.id) ?? [];
  })();

  const linePlotData = getReplotsBottomLineData({
    entities: data?.composition,
    plotName: name,
    subfilters,
  });

  return (
    <PlotCard
      title={title}
      menu={['expand', 'download-png', 'generate-link', 'download-data']}
      onExport={() =>
        downloadBottomLineChart({
          data: data,
          name: name,
          subfilters,
          fileName: BottomPlotNameLookup[name].toLowerCase(),
          entitiesLabel: CompositionSupportedViewTypesLookup[viewType],
        })
      }
      plotShare={{
        data: linePlotData,
        chartConfig: {
          chartType: D3ChartNames.ReplotsLineChart,
          chartProps: { format, colors: lineChartColors },
        },
      }}
      topRight={
        <SubFilter
          hideTriggerDefault={subfilter.hideTriggerDefault || true}
          {...subfilter}
        />
      }
      {...plotCardProps}
    >
      <LineChart data={linePlotData} format={format} colors={lineChartColors} />
    </PlotCard>
  );
};
