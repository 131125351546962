import {
  GetTalentDiscoveryTooltipPayload,
  TalentDiscoveryV1_5Filter,
} from '@revelio/data-access';
import { TalentDiscoveryFilterState } from '../filters/td-filter-reducer';
import { serialiseTalentDiscoveryFilters } from './serialiseTalentDiscoveryFilters';
import {
  SelectionCategories,
  SelectionList,
  ValidValueTypes,
} from '@revelio/filtering';
import { getFilterChildSelectionListIds } from '@revelio/filtering';

export const serialiseTooltipFilters = async (
  filters: TalentDiscoveryFilterState['filters'],
  selectionLists: SelectionList<ValidValueTypes>[]
): Promise<GetTalentDiscoveryTooltipPayload> => {
  const allSerialisedSelectedFilters = await serialiseTalentDiscoveryFilters(
    filters,
    true
  );

  const supportedTooltipSerialisedFiltersExcludingRoleAndRics = Object.entries(
    allSerialisedSelectedFilters
  ).reduce((acc, [key, value]) => {
    // these serialised filters normally include current vs previous, tooltip only takes into account ids
    if (
      [SelectionCategories.COMPANY, SelectionCategories.SENIORITY].includes(
        key as SelectionCategories
      )
    ) {
      return { ...acc, [key]: (value as { ids: number[] })?.ids };
    }

    if ([SelectionCategories.ETHNICITY].includes(key as SelectionCategories)) {
      return { ...acc, [key]: value };
    }

    return acc;
  }, {} as GetTalentDiscoveryTooltipPayload);

  return {
    ...supportedTooltipSerialisedFiltersExcludingRoleAndRics,
    // tooltip sources don't support cross granularity role filtes (e.g. role_k7 + role_k150 filter)
    ...getHomogenisedRoleFilterIds({
      serialisedSelectedFilters: allSerialisedSelectedFilters,
      selectionLists,
    }),
    ...getHomogenisedRicsFilterIds({
      serialisedSelectedFilters: allSerialisedSelectedFilters,
      selectionLists,
    }),
  };
};

const getHomogenisedRoleFilterIds = ({
  serialisedSelectedFilters,
  selectionLists,
}: {
  serialisedSelectedFilters: TalentDiscoveryV1_5Filter;
  selectionLists: SelectionList<ValidValueTypes>[];
}) => {
  const jobCategoryIds = (
    serialisedSelectedFilters.job_category?.ids || []
  ).map((id) => id.toString());
  const roleK150Ids = (serialisedSelectedFilters.role_k150?.ids || []).map(
    (id) => id.toString()
  );
  const roleK1500Ids = (serialisedSelectedFilters.role_k1500?.ids || []).map(
    (id) => id.toString()
  );

  const jobCategoryIdsToRoleK150Ids = getFilterChildSelectionListIds({
    selectedFilterValues: jobCategoryIds ?? [],
    selectionLists,
    selectedFilterSelectionListId: SelectionCategories.JOB_CATEGORY,
    noSelectedFilterSelectionListId: SelectionCategories.JOB_CATEGORY,
    isDashboardFilterRoles: true,
  });

  const roleK150IdsToRoleK1500Ids = getFilterChildSelectionListIds({
    selectedFilterValues: [...jobCategoryIdsToRoleK150Ids, ...roleK150Ids],
    selectionLists,
    selectedFilterSelectionListId: SelectionCategories.ROLE_K150,
    noSelectedFilterSelectionListId: SelectionCategories.ROLE_K1500,
    isDashboardFilterRoles: true,
  });

  // Limiting to 300 as api request exponentially grows (~6 seconds for 300)
  const allRoleK1500Ids = [...roleK150IdsToRoleK1500Ids, ...roleK1500Ids].slice(
    0,
    300
  );

  if (roleK1500Ids.length > 0) {
    return {
      [SelectionCategories.ROLE_K1500]: allRoleK1500Ids,
    };
  }

  if (roleK150Ids.length > 0) {
    return {
      [SelectionCategories.ROLE_K150]: [
        ...jobCategoryIdsToRoleK150Ids,
        ...roleK150Ids,
      ],
    };
  }

  if (jobCategoryIds.length > 0) {
    return { [SelectionCategories.ROLE_K7]: jobCategoryIds };
  }

  return {};
};

const getHomogenisedRicsFilterIds = ({
  serialisedSelectedFilters,
  selectionLists,
}: {
  serialisedSelectedFilters: TalentDiscoveryV1_5Filter;
  selectionLists: SelectionList<ValidValueTypes>[];
}) => {
  const ricsK10Ids = (serialisedSelectedFilters.rics_k10?.ids || []).map((id) =>
    id.toString()
  );
  const ricsK50Ids = (serialisedSelectedFilters.rics_k50?.ids || []).map((id) =>
    id.toString()
  );

  const ricsK400Ids = (serialisedSelectedFilters.rics_k400?.ids || []).map(
    (id) => id.toString()
  );

  const ricsK10IdsToRicsK50Ids = selectionLists
    .find((list) => list.id === SelectionCategories.RICS_K50)
    ?.value.filter((item) =>
      ricsK10Ids.includes((item as unknown as { rics_k10: string }).rics_k10)
    )
    .map((item) => item.id.toString());

  const allRicsK50Ids = [
    ...(ricsK10IdsToRicsK50Ids || []),
    ...ricsK50Ids,
  ].filter(Boolean);

  const ricsK50IdsToRicsK400Ids = selectionLists
    .find((list) => list.id === SelectionCategories.RICS_K400)
    ?.value.filter((item) =>
      allRicsK50Ids.includes((item as unknown as { rics_k50: string }).rics_k50)
    )
    .map((item) => item.id.toString());

  // Limiting to 50 to protect the api
  const allRicsK400Ids = [...(ricsK50IdsToRicsK400Ids || []), ...ricsK400Ids]
    .slice(0, 50)
    .filter(Boolean);

  if (ricsK400Ids.length) {
    return {
      [SelectionCategories.RICS_K400]: allRicsK400Ids,
    };
  }

  if (ricsK50Ids.length) {
    return {
      [SelectionCategories.RICS_K50]: allRicsK50Ids,
    };
  }

  if (ricsK10Ids.length) {
    return {
      [SelectionCategories.RICS_K10]: ricsK10Ids,
    };
  }

  return {};
};
