import { ContactSalesButton } from '@revelio/composed';
import { PageTitles, PrimaryFilters, PrimaryView } from '@revelio/core';
import {
  FilterMenuLimits,
  FilterSets,
  GEOGRAPHY_GRANULARITY_FILTERS,
  PrimaryFilterLimits,
  ROLE_GRANULARITY_FILTERS,
  SKILL_GRANULARITY_FILTERS,
  SelectionCategories,
  ViewTypes,
  RICS_AND_COMPANY_FILTERS,
} from '@revelio/filtering';
import Postings, {
  metricModeFilterId,
  providerFilterId,
} from '../../../postings/postings';
import { View } from '@revelio/data-access';

const title = [PageTitles.COMPANY, PageTitles.JOB_POSTINGS];

const otherFilters = [providerFilterId, metricModeFilterId];

// const requiredParams = [SelectionCategories.DATE_RANGE_FULL];

export function CompanyPostings() {
  const primaryViewFilters = [
    {
      filters: RICS_AND_COMPANY_FILTERS,
      isNested: true,
      limit: 6,
    },
  ];

  const selectableFilters = [
    SelectionCategories.SAVED_FILTER_SET,
    SelectionCategories.DATE_RANGE_FULL,
    {
      filters: ROLE_GRANULARITY_FILTERS,
      isNested: true,
      limit: 10,
    },
    {
      filters: GEOGRAPHY_GRANULARITY_FILTERS,
      isNested: true,
      limit: 10,
    },
    SelectionCategories.SENIORITY,
    {
      filters: SKILL_GRANULARITY_FILTERS,
      isNested: true,
      limit: 10,
    },
    SelectionCategories.RAW_TITLE,
    SelectionCategories.KEYWORD,
  ];

  return (
    <Postings
      title={title}
      primaryView={PrimaryView.COMPANY}
      viewType={ViewTypes.COMPANY}
      primaryFilter={PrimaryFilters.COMPANY}
      sharedFilterSetId={FilterSets.COMPANY}
      filterSet={FilterSets.COMPANY_POSTINGS}
      // TODO: Re-enable when data range filter is fixed
      // requiredParams={requiredParams}
      primaryViewFilters={primaryViewFilters}
      primaryFiltersLimit={PrimaryFilterLimits.POSTINGS}
      selectableFilters={selectableFilters}
      filterMenuLimits={FilterMenuLimits.POSTINGS}
      otherFilters={otherFilters}
      additionalNonActiveFilters={[providerFilterId, metricModeFilterId]}
      isGqlQuery={true}
      isGoRequest={true}
      trialNoResultsMessage={
        <>
          Can't find a company?
          <ContactSalesButton
            buttonProps={{
              size: 'sm',
              variant: 'link',
              fontWeight: 400,
              paddingTop: 2,
              paddingLeft: 1,
            }}
            page={PageTitles.JOB_POSTINGS}
          />
        </>
      }
      savedSetView={View.Company}
    />
  );
}

export default CompanyPostings;
