import {
  BarData,
  BoxGroup,
  KdeData,
  LineData,
  SankeyData,
  StackedBar,
} from '@revelio/replots';

export type SheetData = {
  [key: string]: string | number;
};

// LineChart
type ConvertLineToSheetProps = {
  data: LineData[];
};

export const convertLineToSheet = ({
  data,
}: ConvertLineToSheetProps): SheetData[] => {
  const allDates = Array.from(
    new Set(
      data.flatMap((series) =>
        series.values.map((point) => point.date.split('T')[0])
      )
    )
  ).sort();

  const seriesMap = new Map(
    data.map((series) => [
      series.label,
      new Map(
        series.values.map((point) => [point.date.split('T')[0], point.value])
      ),
    ])
  );

  return allDates.map((date) => ({
    Date: date,
    ...Object.fromEntries(
      data.map((series) => [
        series.label,
        seriesMap.get(series.label)?.get(date) ?? null,
      ])
    ),
  }));
};

// BarChart
type ConvertBarToSheetProps = {
  data: BarData[];
  dimensionName: string;
  metricName: string;
};

export const convertBarToSheet = ({
  data,
  dimensionName,
  metricName,
}: ConvertBarToSheetProps): SheetData[] =>
  data.map((d) => ({
    [dimensionName]: d.label ?? '',
    [metricName]: d.value ?? '',
  }));

// StackedBarChart
type ConvertStackedBarToSheetProps = {
  data: StackedBar[];
  dimensionName: string;
  metricName: string;
};
export const convertStackedBarToSheet = ({
  data,
  dimensionName,
  metricName,
}: ConvertStackedBarToSheetProps): SheetData[] => {
  const sheetData = data.reduce<{ [key: string]: SheetData }>(
    (acc, { label, segments }) => {
      const segmentData = Object.entries(segments);
      const segmentTotal = segmentData.reduce(
        (acc, [_segment, value]) => acc + value,
        0
      );

      Object.entries(segments).forEach(([segment, value]) => {
        acc[segment] = {
          ...acc?.[segment],
          [`${label} ${metricName}`]: value,
          [`${label} Share`]: value / segmentTotal,
        };
      });
      return acc;
    },
    {}
  );

  return Object.entries(sheetData).map(([key, value]) => ({
    [dimensionName]: key,
    ...value,
  }));
};

// KdeChart
type ConvertKdeToSheetProps = {
  data: KdeData[];
  dimensionName: string;
  metricName: string;
};

export const convertKdeToSheet = ({
  data,
  dimensionName,
}: ConvertKdeToSheetProps): SheetData[] =>
  data.flatMap((d) =>
    d.values.map(
      (v): SheetData => ({
        [dimensionName]: d.label,
        value: v.value,
        density: v.kde,
        cdf: v.cdf,
      })
    )
  );

// BoxPlot
const BoxPlotMetrics = ['P10', 'Median', 'P90'] as const;
const BoxPlotDataMapping = {
  P10: 'percentile10',
  Median: 'percentile50',
  P90: 'percentile90',
} as const;

type ConvertBoxPlotToPivotSheetProps = {
  data: BoxGroup[];
  rowHeader: string;
  columnHeader: string;
};

export const convertBoxPlotToPivotSheet = ({
  data,
  rowHeader,
  columnHeader,
}: ConvertBoxPlotToPivotSheetProps): SheetData[] => {
  const dimensions = Array.from(
    new Set(data.map((item) => item.secondaryDimension))
  );

  const header1: SheetData = { [columnHeader]: columnHeader };
  const header2: SheetData = { [columnHeader]: rowHeader };

  dimensions.forEach((dim) => {
    BoxPlotMetrics.forEach((metric, index) => {
      header1[`${dim} ${metric}`] = index === 0 ? dim : '';
      header2[`${dim} ${metric}`] = metric;
    });
  });

  const pivotedData = data.reduce<Record<string, SheetData>>((acc, item) => {
    const key = item.dimension.toString();

    if (!acc[key]) {
      acc[key] = { [columnHeader]: item.dimension };
    }

    BoxPlotMetrics.forEach((metric) => {
      acc[key][`${item.secondaryDimension} ${metric}`] =
        item.boxData[BoxPlotDataMapping[metric]];
    });

    return acc;
  }, {});

  return [header1, header2, ...Object.values(pivotedData)];
};

/** ================ Sankey ================ */
type ConvertSankeyToSheetProps = {
  data: SankeyData;
};

export const convertSankeyToSheet = ({
  data,
}: ConvertSankeyToSheetProps): SheetData[] =>
  data.links.map((link) => ({
    Source: link.source,
    Target: link.target,
    Count: link.value,
  }));
