import { graphql } from '../gql';

export const GET_CLIENTS_SERVER = graphql(`
  query GetClientDetailsServer($input: [String!]) {
    clients(ids: $input) {
      client_name
      users {
        id
        email
      }
    }
  }
`);

export const UPDATE_USER_SERVER = graphql(`
  mutation UpdateUserServer($input: UserRequest!) {
    updateUser(input: $input) {
      id
    }
  }
`);

export const TALENT_DISCOVERY_GET_MAP_STATS = graphql(`
  query GetTalentDiscoveryMapStats(
    $company: [ID!]
    $msa: [ID!]
    $country: [ID!]
    $job_category: [ID!]
    $role_k150: [ID!]
    $role_k1500: [ID!]
    $seniority: [ID!]
    $ethnicity: [ID!]
    $start_date: String
    $end_date: String
    $posting_start_date: String
    $posting_end_date: String
    $provider: [Int!]
    $metric_mode: String
    $dim1: Dimension1
    $custom_role: CustomRoleFilter
    $rics_k10: [ID!]
    $rics_k50: [ID!]
    $rics_k400: [ID!]
  ) {
    composition(
      filters: {
        company: $company
        msa: $msa
        country: $country
        job_category: $job_category
        role_k150: $role_k150
        role_k1500: $role_k1500
        seniority: $seniority
        ethnicity: $ethnicity
        start_date: $start_date
        end_date: $end_date
        custom_role: $custom_role
        rics_k10: $rics_k10
        rics_k50: $rics_k50
        rics_k400: $rics_k400
      }
      dim1: $dim1
    ) {
      metadata {
        id
        shortName
        longName
      }
      metrics {
        growth_rate {
          timeseries {
            id
            date
            value
          }
        }
        headcount {
          timeseries {
            id
            date
            value
          }
        }
      }
    }
    rawComposition: composition(
      filters: {
        company: $company
        msa: $msa
        country: $country
        job_category: $job_category
        role_k150: $role_k150
        role_k1500: $role_k1500
        seniority: $seniority
        ethnicity: $ethnicity
        raw_metrics: true
        start_date: $start_date
        end_date: $end_date
        custom_role: $custom_role
        rics_k10: $rics_k10
        rics_k50: $rics_k50
        rics_k400: $rics_k400
      }
      dim1: $dim1
    ) {
      metadata {
        id
        shortName
        longName
      }
      metrics {
        growth_rate {
          timeseries {
            id
            date
            value
          }
        }
        headcount {
          timeseries {
            id
            date
            value
          }
        }
      }
    }
    posting(
      filters: {
        company: $company
        msa: $msa
        country: $country
        job_category: $job_category
        role_k150: $role_k150
        role_k1500: $role_k1500
        seniority: $seniority
        provider: $provider
        start_date: $posting_start_date
        end_date: $posting_end_date
        metric_mode: $metric_mode
        custom_role: $custom_role
        rics_k10: $rics_k10
        rics_k50: $rics_k50
        rics_k400: $rics_k400
      }
      dim1: $dim1
      dim2: date
    ) {
      metadata {
        id
        shortName
        longName
      }
      category {
        metadata {
          shortName
        }
        metrics {
          time_to_fill
          active
        }
      }
    }
  }
`);

export const USER_SIMPLE_DETAILS_QUERY = graphql(`
  query GetUserSimpleDetails($ids: [String]) {
    users(ids: $ids) {
      id
      active
      name
      username
      role
    }
  }
`);

export const USER_RESUMES_QUERY = graphql(`
  query GetUserResumes($ids: [String]) {
    users(ids: $ids) {
      id
      resumes {
        resume_id
        name
        enriched_data
        download_link
      }
    }
  }
`);

export const USER_DETAILS_QUERY = graphql(`
  query GetUserDetails($ids: [String]) {
    users(ids: $ids) {
      id
      name
      username
      email
      role
      client_name
      live
      active
      tabs
      company_lists
      linkup_postings
      unified_postings
      num_seats
      custom_taxonomy
      metadata {
        key
        value
      }
    }
  }
`);

export const LINKEDIN_TO_RCID_QUERY = graphql(`
  query LinkedinUrlToRCID($urls: [String!]!) {
    linkedinUrlToRCID(req: { urls: $urls }) {
      rcids {
        LinkedinUrl
        RCID
      }
    }
  }
`);

export const LINKEDIN_TO_USER_QUERY = graphql(`
  query LinkedinUrlToUser($urls: [String!]!) {
    linkedinUrlToUser(req: { urls: $urls }) {
      users {
        LinkedinUrl
        User {
          OldUserId
          User {
            user_id
            first_name
            last_name
            email
            current_title
            current_company
            start_date
            end_date
            longitude
            latitude
            RCID
            gender
            ethnicity
            location
            highest_degree
            salary_base
            salary_total
            prestige
            remote_suitability
            years_of_experience
            flight_risk
            linkedin_url
            personal_email
            phone_number
            education
            work_history {
              first_name
              last_name
              email
              current_title
              current_company
              start_date
              end_date
              longitude
              latitude
              RCID
              gender
              ethnicity
              location
              highest_degree
              salary_base
              salary_total
              prestige
              remote_suitability
              years_of_experience
              flight_risk
              linkedin_url
              personal_email
              phone_number
            }
            education_history {
              rsid
              start_date
              end_date
              title
              university_name
            }
          }
        }
      }
    }
  }
`);

export const GET_ENTITY_DATA = graphql(`
  query GetEntityData($filters: EntityFilters) {
    entity(filters: $filters) {
      summary {
        name
        description
        founded
        headquarters
      }
      competitors {
        metadata {
          id
          shortName
          longName
        }
        closeness_score
      }
    }
  }
`);

export const COMPOSITION_GET_SUMMARY_DATA = graphql(`
  query CompositionSummaryData($filters: Filters, $dim1: Dimension1) {
    composition(filters: $filters, dim1: $dim1, dim2: month) {
      metadata {
        id
        shortName
        longName
        type
        __typename
      }
      metrics {
        __typename
        # Single value metrics
        headcount {
          timeseries {
            id
            date
            value
          }
        }
        growth_rate {
          timeseries {
            id
            date
            value
          }
        }
        hiring_rate {
          timeseries {
            id
            date
            value
          }
        }
        attrition_rate {
          timeseries {
            id
            date
            value
          }
        }
      }
    }
  }
`);

export const SENTIMENT_GET_SUMMARY_DATA = graphql(`
  query SentimentGetSummaryData($filters: SentimentFilters, $dim1: Dimension1) {
    sentiment2d(filters: $filters, dim1: $dim1, dim2: month) {
      metadata {
        id
        shortName
        longName
      }
      category {
        metadata {
          id
          shortName
          longName
        }
        metrics {
          overallRating
          overallRatingCount
          businessOutlookRating
          businessOutlookRatingCount
          compensationsBenefitsRating
          compensationsBenefitsRatingCount
        }
      }
    }
  }
`);

export const POSTINGS_GET_ACTIVE = graphql(`
  query PostingsGetActive($filters: PostingFilters, $dim1: Dimension1) {
    posting(filters: $filters, dim1: $dim1, dim2: date) {
      metadata {
        id
        shortName
        longName
      }
      category {
        metadata {
          id
          shortName
          longName
        }
        metrics {
          active
        }
      }
    }
  }
`);
