import {
  bisector,
  curveBasis,
  format as d3Format,
  extent,
  group,
  line,
  scaleLinear,
  scaleOrdinal,
  scaleUtc,
} from 'd3';
import { isDate, isNumber } from 'lodash';
import { useRef, useState } from 'react';

import { CommonPlotProps, FormatType } from '../../types';
import {
  getFormatter,
  getMaxTextWidth,
  getScaleTicks,
  isDateValid,
  useRenderCheck,
  useResizeObserver,
  xAxisTickCount,
  yAxisTickCount,
} from '../../utilities';
import { shortMonth } from '../../utilities/date-formatter';
import { PRIMARY_COLOR, plotColors } from '../../utilities/plot-colors';
import { tickAnchor } from '../../utilities/tick-anchor';
import { AXIS_NO_WRAP_HEIGHT, AxisLabel } from '../axis/axis-label';
import { PlotLoadWrapper } from '../plot-loader/plot-loader';
import {
  PlotTooltip,
  TooltipHoverLine,
  TooltipPosition,
  useTooltipController,
} from '../plot-tooltip';
import styles from './line-chart.module.css';
import { LineTooltip, LineTooltipProps } from './line-tooltip';

export type LineDatum = {
  date: string;
  value: number | null;
  secondaryValue?: number | null;
};

type LineDatumWithDate = Omit<LineDatum, 'date'> & { date: Date };

export type LineData = {
  label: string;
  values: LineDatum[];
  color?: string;
};

type LineDataWithDates = Omit<LineData, 'values'> & {
  values: LineDatumWithDate[];
};

export type AxisOptions = {
  min: number;
  max: number;
};

export type LineChartProps = {
  data: LineData[];
  colors?: string[];
  showGridLines?: boolean;
  yAxis?: AxisOptions;
} & CommonPlotProps;

const Y_AXIS_MAX_WIDTH = 50;
const X_AXIS_HEIGHT = 12;

const CHART_PADDING_LEFT = 6;
const CHART_PADDING_TOP = 8;
const CHART_PADDING_BOTTOM = 8;

const bisect = bisector<Date, Date>((d) => d).center;

export const LineChart = ({
  data,
  yAxis,
  format = FormatType.SI,
  showGridLines = true,
  loading = false,
  colors,
  renderUpdate,
}: LineChartProps) => {
  const { containerRef, width, height } = useResizeObserver();

  const xAxisHeight = X_AXIS_HEIGHT;

  const plotHeight =
    height - xAxisHeight - CHART_PADDING_TOP - CHART_PADDING_BOTTOM;

  const dataWithDates: LineDataWithDates[] = data.map((d) => ({
    ...d,
    values: d.values
      .map((v) => ({ ...v, date: new Date(v.date) }))
      .filter((d) => isDateValid(d.date)),
  }));

  /** ================================
   * Color Scale
   ================================ */
  const colorScale = scaleOrdinal<string>()
    .domain(dataWithDates.map((d) => d.label))
    .range(
      dataWithDates.map((d, i) => d.color || colors?.[i] || plotColors[i])
    );

  /** ================================
   * Y Axis
   ================================ */
  const allValues = dataWithDates
    .flatMap((d) => d.values.map((v) => v.value))
    .filter((v) => v !== null);
  const domain = yAxis ? [yAxis.min, yAxis.max] : extent(allValues);
  const yScaleTickCount = yAxisTickCount(plotHeight);

  const yScale =
    isNumber(domain[0]) && isNumber(domain[1])
      ? scaleLinear()
          .domain([domain[0], domain[1]])
          .nice(yScaleTickCount)
          .range([plotHeight, 0])
      : null;

  const formatValue = getFormatter(format, { values: allValues });

  const yTicks = (() => {
    if (!yScale) return [];

    const yScaleTicks = yScale
      .ticks(yScaleTickCount)
      .map((tick) => ({ value: tick, label: formatValue(tick) }));

    if (yScaleTickCount <= 3 && yScaleTicks.length > yScaleTickCount) {
      return getScaleTicks({
        scale: yScale,
        tickCount: yScaleTickCount,
        format: formatValue,
      });
    }

    return yScaleTicks;
  })();

  const yAxisWidth = yTicks
    ? Math.min(
        getMaxTextWidth({ texts: yTicks?.map((t) => t.label) }),
        Y_AXIS_MAX_WIDTH
      )
    : Y_AXIS_MAX_WIDTH;

  const plotWidth = width - yAxisWidth - CHART_PADDING_LEFT;
  const isPlotSizeValid = plotWidth > 0 && plotHeight > 0;

  /** ================================
   * X Axis
   ================================ */
  const groupedData = group(
    dataWithDates.flatMap((d) =>
      d.values.map((v) => ({ ...v, label: d.label }))
    ),
    (d) => d.date
  );

  const allDates = Array.from(groupedData.keys()).sort(
    (a, b) => a.getTime() - b.getTime()
  );
  const dateDomain = extent(allDates);

  const xScaleTickCount = xAxisTickCount(plotWidth);
  const xScale =
    isDate(dateDomain[0]) && isDate(dateDomain[1])
      ? scaleUtc().domain([dateDomain[0], dateDomain[1]]).range([0, plotWidth])
      : null;

  const xTicks = xScale?.ticks(xScaleTickCount).map((tick) => ({
    value: tick,
    label: shortMonth(xScale?.tickFormat()(tick)),
  }));

  /** ================================
   * Line Path
   ================================ */
  const lineGenerator =
    xScale && yScale
      ? line<LineDatumWithDate>()
          .x((d) => xScale(d.date))
          .y((d) => yScale(d.value || 0))
          .defined((d) => d.value !== null)
          .curve(curveBasis)
      : null;

  const lines = dataWithDates
    .map((d) => {
      if (!lineGenerator) return null;

      const path = lineGenerator(d.values);
      if (!path) return null;

      return { ...d, path };
    })
    .filter((d) => !!d)
    .map((d) => ({ ...d, color: colorScale(d.label) }));

  /** ================================
   * Tooltip
   ================================ */
  const tooltipFormatType = (() => {
    if (format === FormatType.SI) return FormatType.INTEGER;
    if (format === FormatType.CURRENCY) {
      return FormatType.CURRENCY_INTEGER;
    }
    return format;
  })();
  const tooltipFormatValue = getFormatter(tooltipFormatType, {
    values: allValues,
  });

  const [tooltipRows, setTooltipRows] = useState<LineTooltipProps | null>(null);
  const [tooltipPosition, setTooltipPosition] =
    useState<TooltipPosition | null>(null);

  const handleTooltipMove = (position: [number, number]) => {
    if (!xScale) return;

    const date = xScale.invert(position[0]);
    const index = bisect(allDates, date);
    const bisectDate = allDates[index];

    const dataPoints = groupedData.get(bisectDate);
    if (!dataPoints) return;

    const title = bisectDate.toLocaleString('default', {
      month: 'long',
      year: 'numeric',
      timeZone: 'UTC',
    });

    setTooltipRows({
      title,
      rows: dataPoints
        .map((d) => ({
          ...{ color: colorScale(d.label) || PRIMARY_COLOR },
          label: d.label,
          value:
            typeof d.value === 'number'
              ? `${tooltipFormatValue(d.value)}${typeof d.secondaryValue === 'number' ? ` / ${d3Format(',.0f')(d.secondaryValue)}` : ''}`
              : '-',
          sortValue: d.value || 0,
        }))
        .sort((a, b) => b.sortValue - a.sortValue)
        .map(({ sortValue, ...row }) => row),
    });

    const maxValue = Math.max(
      ...dataPoints.map((d) => d.value).filter((v) => v !== null)
    );

    const y =
      Number.isFinite(maxValue) && yScale ? yScale(maxValue) : plotHeight / 2;

    setTooltipPosition({ x: position[0], y });
  };

  const tooltipController = useTooltipController({
    onHover: handleTooltipMove,
  });

  const chartRef = useRef<SVGGElement>(null);
  useRenderCheck(chartRef, { renderUpdate });

  // Need at least 2 data points to draw a line
  const noData = data.length === 0 || data.every((d) => d.values.length < 2);

  return (
    <div
      ref={containerRef}
      className={styles['container']}
      data-testid="plot-LineChart"
    >
      <PlotLoadWrapper loading={loading} noData={noData}>
        <svg width="100%" height="100%">
          {isPlotSizeValid && (
            <g transform={`translate(0, ${CHART_PADDING_TOP})`}>
              <g
                id="y-axis"
                data-testid="plot-y-axis"
                className={styles['yAxis']}
              >
                {yScale &&
                  yTicks?.map((tick, i) => (
                    <AxisLabel
                      key={i}
                      y={yScale(tick.value)}
                      width={yAxisWidth}
                      availableHeight={AXIS_NO_WRAP_HEIGHT}
                      label={tick.label}
                    />
                  ))}
              </g>
              <g
                id="x-axis"
                data-testid="plot-x-axis"
                transform={`translate(${yAxisWidth + CHART_PADDING_LEFT}, ${plotHeight + CHART_PADDING_BOTTOM})`}
                className={styles['xAxis']}
              >
                {xScale &&
                  xTicks?.map((tick, i) => (
                    <text
                      key={i}
                      transform={`translate(${xScale(tick.value)}, 0)`}
                      y={9}
                      style={{
                        textAnchor: tickAnchor(
                          i,
                          xTicks.length,
                          xScale(tick.value),
                          plotWidth
                        ),
                      }}
                    >
                      {tick.label}
                    </text>
                  ))}
              </g>
              <g
                id="chart"
                ref={chartRef}
                transform={`translate(${yAxisWidth + CHART_PADDING_LEFT}, 0)`}
                {...tooltipController.gProps}
                className={styles.chart}
              >
                {showGridLines && (
                  <g id="axis-grid">
                    {yScale &&
                      yTicks?.map((tick, i) => (
                        <line
                          key={i}
                          x1={0}
                          x2={plotWidth}
                          y1={yScale(tick.value)}
                          y2={yScale(tick.value)}
                          className={styles['axisGridLine']}
                        />
                      ))}
                  </g>
                )}
                {lines.map((line, i) => (
                  <path
                    key={i}
                    d={line.path}
                    stroke={line.color}
                    className={`line-path ${styles['line-path']}`}
                    data-testid={`line-chart__line`}
                  />
                ))}
                {tooltipController.isVisible && tooltipPosition && (
                  <TooltipHoverLine x={tooltipPosition.x} height={plotHeight} />
                )}
                <rect
                  x={0}
                  y={0}
                  width={plotWidth}
                  height={plotHeight}
                  fill="transparent"
                />
              </g>
            </g>
          )}
        </svg>
        {tooltipPosition && (
          <PlotTooltip
            isVisible={tooltipController.isVisible}
            x={tooltipPosition.x + yAxisWidth}
            y={tooltipPosition.y}
          >
            {tooltipRows && (
              <LineTooltip title={tooltipRows.title} rows={tooltipRows.rows} />
            )}
          </PlotTooltip>
        )}
      </PlotLoadWrapper>
    </div>
  );
};
