import { useGetLoggedInUser } from '@revelio/auth';
import { Tab } from '@revelio/data-access';
import { useMemo } from 'react';
import { FiFileText } from 'react-icons/fi';
import { useNavigate } from 'react-router';
import taxonomyCatalogIcon from '../../../../images/icons/taxonomy-catalog-icon.svg';

export const useMethodologyMenuItems = () => {
  const navigate = useNavigate();
  const { loggedInUser } = useGetLoggedInUser();
  const { tabs } = loggedInUser;

  const menuItems = useMemo(() => {
    const items = [];
    if (tabs?.includes(Tab.DataDictionary)) {
      items.push({
        id: 'data-dictionary',
        icon: FiFileText,
        children: 'Data Dictionary',
        onClick: () => navigate('/data-dictionary'),
      });
    }

    /* Taxonomy Catalog is enabled for everyone */
    items.push({
      id: 'taxonomy-catalog',
      iconSrc: taxonomyCatalogIcon,
      children: 'Taxonomy Catalog',
      onClick: () => navigate('/taxonomy-catalog'),
    });
    return items;
  }, [navigate, tabs]);

  return {
    showMenu: menuItems.length > 0,
    menuItems,
  };
};
