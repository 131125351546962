import { CompositionDataQuery } from '@revelio/data-access';
import { BarChart, FormatType } from '@revelio/replots';
import { getRoleCompositionData } from './utils/get-role-composition-data';
import { RoleSummaryMetric } from '../types';
import { PlotCard, convertBarToSheet } from '../../../shared/components';

interface RoleSummaryTopSkillsProps {
  data: CompositionDataQuery | undefined;
}

export const RoleSummaryTopSkills = ({ data }: RoleSummaryTopSkillsProps) => {
  const barData = (() => {
    const compositionData = getRoleCompositionData(data);
    const skillsData = compositionData[RoleSummaryMetric.Skills]?.[0]?.value;

    if (!skillsData?.length) return [];

    return skillsData
      .filter(
        (item): item is NonNullable<typeof item> =>
          item !== null && item.metadata?.longName != null && item.value != null
      )
      .map((item) => ({
        label: item.metadata.longName as string,
        value: (item.value as number) / 100, // Convert from decimal to percentage
      }));
  })();

  return (
    <PlotCard
      title="Top Skills"
      info={<div>Skills distribution of this role</div>}
      menu={['expand', 'download-png', 'download-data']}
      exportData={convertBarToSheet({
        data: barData,
        dimensionName: 'Skill',
        metricName: 'Percentage',
      })}
    >
      <BarChart data={barData} loading={!data} format={FormatType.PERCENTAGE} />
    </PlotCard>
  );
};
