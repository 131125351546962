import { Box, Divider, Flex, Text } from '@chakra-ui/layout';
import theme from '@chakra-ui/theme';
import { GetEntityDataQuery } from '@revelio/data-access';
import { Card } from '@revelio/layout';
import { PlotInfoComp } from '@revelio/composed';
import { Textfit } from 'react-textfit';
import { useResizeObserver } from '@revelio/core';

type EntitySummary = NonNullable<
  NonNullable<GetEntityDataQuery['entity']>['summary']
>;

interface CompanySummaryOverviewProps {
  description: EntitySummary['description'];
  founded: EntitySummary['founded'];
  headquarters: EntitySummary['headquarters'];
}

export const CompanySummaryOverview = ({
  description,
  founded,
  headquarters,
}: CompanySummaryOverviewProps) => {
  const factSize = '54px';
  const { containerRef, width, height } = useResizeObserver();

  return (
    <Card p="12px 20px">
      <Flex height="100%" flexDirection="column" justifyContent="space-between">
        <Box height={`calc(100% - ${factSize})`} ref={containerRef}>
          <Flex mb={3}>
            <Text fontWeight={600} fontSize={14}>
              Description
            </Text>
            <PlotInfoComp
              plotInfoBody={
                <Text color="white">
                  Descriptions are sourced from online entity profile pages or
                  AI generated text.
                </Text>
              }
              plotInfoConfig={{
                popoverPlacement: 'right',
                iconBoxSize: 2.5,
              }}
            />
          </Flex>

          {width && height ? (
            <Textfit
              mode="multi"
              min={10}
              max={200}
              style={{
                height: '90%',
                lineHeight: '1.2',
                fontWeight: 300,
              }}
            >
              <Text>{description}</Text>
            </Textfit>
          ) : (
            <Text
              style={{
                height: '90%',
                lineHeight: '1.2',
                fontWeight: 300,
              }}
            >
              {description}
            </Text>
          )}
        </Box>

        <Box height={factSize}>
          <Divider my={4} />
          <Flex>
            <Flex
              pr={3}
              mr={3}
              borderRight={`1px solid ${theme.colors.gray[200]}`}
            >
              <Text fontSize={14} fontWeight={600} mr={1}>
                Founded:
              </Text>
              <Text fontSize={14} fontWeight={300}>
                {founded}
              </Text>
            </Flex>
            <Text fontSize={14} fontWeight={600} mr={1}>
              Headquarters:
            </Text>
            <Text
              fontSize={14}
              fontWeight={300}
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
              title={headquarters || ''}
            >
              {headquarters}
            </Text>
          </Flex>
        </Box>
      </Flex>
    </Card>
  );
};
